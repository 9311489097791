import { EntityQuery, FilterQueryOp } from 'breeze-client';
import { Bookmark } from '../../models';

const baseQuery = EntityQuery.from('Bookmarks');
const defaultBookmark = baseQuery.where(Bookmark.propName('isDefault'), FilterQueryOp.Equals, true);

export const bookmarkQueries = {
  selectAll: baseQuery,
  selectDefaultBookmark: defaultBookmark
};
