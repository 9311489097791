import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { GroupMembership } from '../models';
import { groupMembershipQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class GroupMembershipRepositoryService extends RepositoryService<GroupMembership> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, groupMembershipQueries.selectAll);
  }
}
