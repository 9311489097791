import { BaseEntity } from '../base-entity';
import { OrganisationWorkspace } from './organisation-workspace';
import { PbiReport } from './pbi-report';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface PbiOrganisationWorkspace {
  id: string;
  createdDate: Date;
  isDeleted: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  organisationWorkspaceId: string;
  pbiWorkspaceType: string;
  periodEnd: Date;
  periodStart: Date;
  status: string;
  tenantKey: string;
  organisationWorkspace: OrganisationWorkspace;
  pbiReports: PbiReport[];
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class PbiOrganisationWorkspace extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
