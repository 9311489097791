import { Entity, EntityType, Validator } from 'breeze-client';

/**
 * A map whose keys define either the names of properties from an object of type `T`, or an empty string denoting the
 * object itself, whose values are a list of associated validators
 */
export type ValidatorMap<T> = {
  [P in keyof T]?: Validator[];
} & { ''?: Validator[] };

/**
 * A function returned by `addBreezeValidators` (or other like it) that when executed will remove the validators that
 * were added by that specific invocation
 */
export type TeardownValidators = () => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NoopTeardownValidators: TeardownValidators = () => {};

/**
 * Receives a map of validators to add to an `EntityType`
 * @param validatorMap the map of validators to add to the `EntityType`
 * @param entityOrEntityType the `EntityType` itself or an instance of a `Entity` whose `EntityType` to add validators to
 * @returns a function that when called will remove the validators that were added
 */
export function addBreezeValidators<T extends Entity>(
  validatorMap: ValidatorMap<T>,
  entityOrEntityType: T | EntityType
): TeardownValidators {
  const et = entityOrEntityType instanceof EntityType ? entityOrEntityType : entityOrEntityType.entityType;

  const entries = Object.entries(validatorMap).map(([key, validators]) => ({
    target: key === '' ? et : et.getProperty(key, true),
    validators
  }));

  entries.forEach(({ target, validators }) => {
    target.validators.push(...validators);
  });

  const validatorMapCopy = { ...validatorMap };
  return () => removeBreezeValidators(validatorMapCopy, entityOrEntityType);
}

function removeBreezeValidatorErrors<T extends Entity>(validatorMap: ValidatorMap<T>, entity: T) {
  const validatorsToRemove = Object.values(validatorMap).flat();
  const existingErrs = entity.entityAspect.getValidationErrors();
  const exitingErrs = existingErrs.filter(err => err.validator && validatorsToRemove.includes(err.validator));
  exitingErrs.forEach(err => {
    entity.entityAspect.removeValidationError(err);
  });
}

/**
 * Receives a map of validators to remove from an `EntityType`. Where the `entityOrEntityType` supplied is an entity
 * instances, then also remove all the existing validation errors that match one of the `Validator`'s being removed
 * @param validatorMap the map of validators to remove from the `EntityType`
 * @param entityOrEntityType the `EntityType` itself or an instance of a `Entity` whose `EntityType` to remove validators from
 */
export function removeBreezeValidators<T extends Entity>(
  validatorMap: ValidatorMap<T>,
  entityOrEntityType: T | EntityType
): void {
  const et = entityOrEntityType instanceof EntityType ? entityOrEntityType : entityOrEntityType.entityType;
  Object.entries(validatorMap).forEach(([key, list]) => {
    const existingValidators = key === '' ? et.validators : et.getProperty(key, true).validators;
    for (let i = existingValidators.length - 1; i >= 0; i--) {
      if (list.includes(existingValidators[i])) {
        existingValidators.splice(i, 1);
      }
    }
  });

  if (!(entityOrEntityType instanceof EntityType)) {
    removeBreezeValidatorErrors(validatorMap, entityOrEntityType);
  }
}
