import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { PbiReport } from '../models';
import { pbiReportQueries } from './queries/pbireport-queries';

@Injectable({ providedIn: 'root' })
export class PbiReportRepositoryService extends RepositoryService<PbiReport> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, pbiReportQueries.selectAll);
  }
}
