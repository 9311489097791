import { Directive, Input, OnDestroy, Optional } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Validator as BreezeValidator, DataProperty } from 'breeze-client';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BreezeEntityTypeSourceDirective } from './breeze-entity-type-source.directive';
import { BreezePropertyDirective } from './breeze-property.directive';

@Directive({
  selector: '[ngModel][breezeProperty][breezePropertyValidators]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: BreezePropertyValidatorDirective,
      multi: true
    }
  ]
})
export class BreezePropertyValidatorDirective implements Validator, OnDestroy {
  private dataProperty?: DataProperty;
  private onChange?: () => void;
  private subscription = new Subscription();
  private validators = new BehaviorSubject<BreezeValidator[]>([]);
  validators$ = this.validators.asObservable();

  @Input() set breezePropertyValidators(list: BreezeValidator[]) {
    if (list) {
      this.setValidators(list);
    }
  }

  constructor(
    private property: BreezePropertyDirective,
    @Optional() entityTypeSource?: BreezeEntityTypeSourceDirective
  ) {
    const entityType$ = entityTypeSource ? entityTypeSource.entityType$ : EMPTY;
    const state$ = entityType$.pipe(
      map((et): DataProperty | null => et.getDataProperty(this.property.name)),
      filter((dp): dp is DataProperty => dp != null),
      map(dataProperty => ({
        dataProperty,
        validators: dataProperty.getAllValidators()
      }))
    );

    const sub = state$.subscribe(({ dataProperty, validators }) => {
      this.dataProperty = dataProperty;
      this.setValidators(validators);
    });
    this.subscription.add(sub);
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  registerOnValidatorChange?(fn: () => void) {
    this.onChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.validators.value.length === 0) return null;

    const validationCtx = Object.assign(
      {
        property: this.dataProperty,
        propertyName: this.property.name
      },
      this.dataProperty ? {} : { displayName: this.property.name }
    );

    return this.validators.value
      .map(v => v.validate(control.value, validationCtx))
      .filter(err => err)
      .reduce((results, err) => Object.assign(results, { [err.key]: err }), {});
  }

  private setValidators(list: BreezeValidator[]) {
    this.validators.next(list);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.onChange && this.onChange();
  }
}
