import { Component, HostBinding } from '@angular/core';
import { FormErrorBaseComponent } from './form-error-base.component';

@Component({
  selector: 'breeze-form-error',
  template: `<ng-container *ngIf="invalid && dirty">{{ errors | breezeValidationMessage }}</ng-container>`,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `
  ]
})
export class BreezeFormErrorComponent extends FormErrorBaseComponent {
  @HostBinding('class.breeze-form-error--invalid') get invalidCss() {
    return this.invalid;
  }
  @HostBinding('class.breeze-form-error--valid') get validCss() {
    return !this.invalid;
  }
}
