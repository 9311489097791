import { core, MetadataStore } from 'breeze-client';

/**
 * Fixes to the breeze-client npm library
 * */
export const registerBreezeLibraryFixes = (_: MetadataStore) => {
  // workaround while we wait for this fix to be published to npm: https://github.com/Breeze/breeze-client/commit/fbe6e4fa571e982c1c04c366cd216321415d0139
  core.isGuid = (value: unknown) =>
    typeof value === 'string' && /^[a-fA-F\d]{8}-(?:[a-fA-F\d]{4}-){3}[a-fA-F\d]{12}$/.test(value);
};
