import { BaseEntity } from '../base-entity';
import { OrganisationPrincipal } from './organisation-principal';
import { PbiOrganisationWorkspace } from './pbi-organisation-workspace';
import { TemplateWorkspace } from './template-workspace';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { NEW_BREEZE_GUID } from '@mri/breeze-entity';
import { DataProperty, DataType, MetadataStore } from 'breeze-client';
import { OrganisationWorkspaceInfo, WorkspaceAvatarInfo, WorkspaceType } from './organisation-workspace-info';
import { validModuleCodeRegEx } from './shared-validators';
/// </code-import>

export interface OrganisationWorkspace {
  id: string;
  capacityId?: string;
  deployStateUpdatedOn: Date;
  enforceAnyStandardRlsRoles: boolean;
  isDeleted: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  moduleCodes?: string;
  ownerId: string;
  periodEnd: Date;
  periodStart: Date;
  region?: string;
  servicePrincipalProfile: string;
  templateWorkspaceId: string;
  tenantKey: string;
  workspaceType: string;
  owner: OrganisationPrincipal;
  pbiWorkspaces: PbiOrganisationWorkspace[];
  templateWorkspace: TemplateWorkspace;
}

/// <module-code> Place module level code between <module-code> tags
export interface OrganisationWorkspace extends OrganisationWorkspaceInfo, WorkspaceAvatarInfo {
  hasActiveDeployment: boolean;
}
/// </module-code>

export class OrganisationWorkspace extends BaseEntity {
  /// <code> Place custom code between <code> tags
  get modules() {
    return TemplateWorkspace.getModules(this.moduleCodes);
  }

  get templateWorkspaceIcon() {
    // IMPORTANT: this assumes that the templateWorkspace navigation property has been loaded/assigned
    return this.templateWorkspace.icon;
  }

  get workspaceName(): string {
    if (!this.entityAspect.isNavigationPropertyLoaded(OrganisationWorkspace.propName('templateWorkspaceIcon'))) {
      return '';
    }
    return `${this.templateWorkspaceIcon.label}${
      this.workspaceType === WorkspaceType.Primary ? '' : ` (${this.workspaceType})`
    }`;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set modules(value: string[]) {
    const moduleCodes = value.join(',');
    const allModule =
      this.templateWorkspace.modules.length === value.length || moduleCodes === TemplateWorkspace.modulePlaceHolder;
    this.moduleCodes = allModule || value.length === 0 ? undefined : moduleCodes;
  }
  //Set ServicePrincipalProfile to placeholder value in-order to trigger a client-onboarding on the server-side
  static servicePrincipalPlaceHolder = '71E9456D-B5DC-4C8A-8BF0-626E77F4C934';

  static authorization = AuthzContextsActionMap.crudFor(EntityName.OrganisationWorkspace);
  static propName(name: keyof OrganisationWorkspace) {
    return name;
  }

  static register(metadataStore: MetadataStore) {
    const et = metadataStore.getAsEntityType(EntityName.OrganisationWorkspace, false);
    const prop = et.getProperty('moduleCodes');
    prop.validators = [...prop.validators, validModuleCodeRegEx];

    et.addProperty(
      new DataProperty({
        name: OrganisationWorkspace.propName('hasActiveDeployment'),
        dataType: DataType.Boolean,
        isNullable: false,
        isPartOfKey: false,
        defaultValue: false,
        validators: []
      })
    );
  }

  override setDeleted() {
    if (!this.entityAspect.entityState.isDeleted() && this.templateWorkspaceId !== NEW_BREEZE_GUID) this.modules = [];
    return super.setDeleted();
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
