import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import {
  FeatureFlags,
  FeatureFlagsService,
  routeResolveEntities,
  RoutingEventsService
} from '@mri-platform/shared/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsResolver implements Resolve<FeatureFlags> {
  constructor(
    private featureFlagService: FeatureFlagsService,
    private router: Router,
    private routingEvents: RoutingEventsService,
    private errorPolicyService: ErrorPolicyService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<FeatureFlags> {
    return this.routingEvents.isInitialNavigation$.pipe(
      routeResolveEntities(() => this.featureFlagService.flags$, this.router, this.errorPolicyService)
    );
  }
}
