import { BaseEntity } from '../base-entity';
import { ServicePrincipal } from './service-principal';
import { TemplateWorkspaceIcon } from './template-workspace-icon';
import { TemplateWorkspaceTargetSettings } from './template-workspace-target-settings';

/// <code-import> Place custom imports between <code-import> tags
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { EntityName } from '@mri-platform/dsg/core';
import { DataProperty, DataType, MetadataStore } from 'breeze-client';
import { validModuleCodeRegEx } from './shared-validators';
/// </code-import>

export interface TemplateWorkspace {
  id: string;
  authorizedServicePrincipalId?: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  periodEnd: Date;
  periodStart: Date;
  productNameOrFamily: string;
  templatePbiWorkspaceName: string;
  tenantKey: string;
  authorizedServicePrincipal: ServicePrincipal;
  icon: TemplateWorkspaceIcon;
  targetSettings: TemplateWorkspaceTargetSettings[];
}

/// <module-code> Place module level code between <module-code> tags
export interface TemplateWorkspace {
  moduleCodes?: string;
}

export enum TemplateType {
  Dev = 'Dev',
  Test = 'Test',
  Prod = 'Prod'
}
/// </module-code>

export class TemplateWorkspace extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization = AuthzContextsActionMap.crudFor(EntityName.TemplateWorkspace);
  static modulePlaceHolder = 'Content';

  public get modules() {
    return TemplateWorkspace.getModules(this.moduleCodes);
  }

  static propName(name: keyof TemplateWorkspace) {
    return name;
  }

  static register(metadataStore: MetadataStore) {
    const et = metadataStore.getAsEntityType(EntityName.TemplateWorkspace, false);
    et.addProperty(
      new DataProperty({
        name: TemplateWorkspace.propName('moduleCodes'),
        dataType: DataType.String,
        isNullable: true,
        isPartOfKey: false,
        defaultValue: null,
        validators: [validModuleCodeRegEx]
      })
    );
  }

  static getModules(moduleCodes: string | undefined) {
    return moduleCodes ? moduleCodes.split(',') : [TemplateWorkspace.modulePlaceHolder];
  }

  getModuleDtos() {
    return this.modules.map(module => ({
      productName: this.productNameOrFamily,
      templateWorkspaceName: this.templatePbiWorkspaceName,
      templateWorkspaceId: this.id,
      moduleName: module,
      icon: this.icon,
      moduleProductName: module !== TemplateWorkspace.modulePlaceHolder ? module : this.productNameOrFamily
    }));
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
