import { BaseEntity } from '../base-entity';
import { Role } from './role';
import { ServicePrincipal } from './service-principal';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface ServicePrincipalRole {
  roleId: string;
  servicePrincipalId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  role: Role;
  servicePrincipal: ServicePrincipal;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ServicePrincipalRole extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
