import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export abstract class FormErrorComponent {
  abstract errors: ValidationErrors | null;
  abstract invalid: boolean;
  abstract dirty: boolean;
}

@Component({
  selector: 'breeze-form-error-base',
  template: `<span>please replace with real implementation</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorBaseComponent implements FormErrorComponent {
  @Input() errors: ValidationErrors | null = null;
  @Input() invalid = false;
  @Input() dirty = false;
}
