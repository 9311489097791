import { Entity, EntityAspect, EntityType, ValidationError } from 'breeze-client';
import {
  EntityInstanceQuery,
  GraphLoadPurpose,
  composeEntityInstanceQueries,
  loadGraphQuery,
  loadGraphsQuery,
  loadNavigationProperty
} from './queries';

function getAssociation(entity: Entity, navigationProperty: string): Entity[] {
  const value: Entity | Entity[] = entity.entityAspect.getPropertyValue(navigationProperty as string);
  return Array.isArray(value) ? value : [value];
}

function isExpandPathLoaded(entity: Entity, expand: string | string[]): boolean {
  const expandSegments = typeof expand === 'string' ? expand.split('.') : expand;
  const [navigationProperty, ...descendantPaths] = expandSegments;

  const isNavPropLoaded = entity.entityAspect.isNavigationPropertyLoaded(navigationProperty);
  if (!isNavPropLoaded) {
    return false;
  }

  if (descendantPaths.length === 0) {
    return true;
  }
  const directAssociations = getAssociation(entity, navigationProperty);
  return directAssociations.every(e => isExpandPathLoaded(e, descendantPaths));
}

export class DefaultEntity implements Entity {
  entityAspect!: EntityAspect;
  entityType!: EntityType;

  get childPropertyNames(): Array<keyof this> {
    return [];
  }

  getChildrenFor(purpose: GraphLoadPurpose = GraphLoadPurpose.View) {
    return this.getChildPropertyNames(purpose).flatMap(propertyName => {
      return getAssociation(this, propertyName as string);
    });
  }

  getLoadGraphQuery(purpose: GraphLoadPurpose = GraphLoadPurpose.View) {
    const paths = this.getPropertyPathsFor(purpose);
    return loadGraphQuery<this>(paths);
  }

  getLoadGraphsQuery(purpose: GraphLoadPurpose = GraphLoadPurpose.View) {
    const paths = this.getPropertyPathsFor(purpose);
    return loadGraphsQuery<this>(paths);
  }

  getLoadChildrenQuery(purpose: GraphLoadPurpose = GraphLoadPurpose.View): EntityInstanceQuery<this> {
    const navigationPropertyQueries = this.getLoadChildrenQueries(purpose);
    return composeEntityInstanceQueries<this>(navigationPropertyQueries);
  }

  getPropertyPathsFor(_: GraphLoadPurpose): string[] {
    return this.childPropertyNames as string[];
  }

  isGraphLoaded(purpose: GraphLoadPurpose = GraphLoadPurpose.View) {
    const expandPaths = this.getPropertyPathsFor(purpose);
    return expandPaths.every(path => {
      return isExpandPathLoaded(this, path);
    });
  }

  setDeleted(): Entity[] {
    const children = this.getChildrenFor(GraphLoadPurpose.Delete);
    const deleted = children.flatMap(entity => {
      if (entity instanceof DefaultEntity) {
        return entity.setDeleted();
      } else {
        entity.entityAspect.setDeleted();
        return entity;
      }
    });
    this.entityAspect.setDeleted();
    return [this, ...deleted];
  }

  getValidationErrors(): ValidationError[];
  getValidationErrors(property: keyof this): ValidationError[];
  getValidationErrors(options: Partial<ValidationError>): ValidationError[];
  getValidationErrors(predicate: (error: ValidationError) => boolean): ValidationError[];
  getValidationErrors(
    propOrOptionOrPredicate?: keyof this | Partial<ValidationError> | ((error: ValidationError) => boolean)
  ): ValidationError[] {
    if (!this.entityAspect.hasValidationErrors) return [];
    const validationErrors = this.entityAspect.getValidationErrors();
    if (typeof propOrOptionOrPredicate === 'string') {
      return validationErrors.filter(x => x.propertyName === propOrOptionOrPredicate);
    }

    if (typeof propOrOptionOrPredicate === 'object') {
      return validationErrors.filter(item =>
        Object.entries(propOrOptionOrPredicate).every(
          ([key, value]) =>
            Object.prototype.hasOwnProperty.call(item, key) && item[key as keyof ValidationError] === value
        )
      );
    }

    if (typeof propOrOptionOrPredicate === 'function') {
      return validationErrors.filter(item => propOrOptionOrPredicate(item));
    }
    return validationErrors;
  }

  removeServerValidationErrors(propertyName?: keyof this) {
    const serverErrors = propertyName
      ? this.getValidationErrors({ isServerError: true, propertyName: propertyName.toString() })
      : this.getValidationErrors({ isServerError: true });

    serverErrors.forEach(err => this.entityAspect.removeValidationError(err));
  }

  private getChildPropertyNames(purpose: GraphLoadPurpose = GraphLoadPurpose.View) {
    return this.getPropertyPathsFor(purpose).map(path => path.split('.')[0] as keyof this);
  }

  private getLoadChildrenQueries(purpose: GraphLoadPurpose = GraphLoadPurpose.View): EntityInstanceQuery[] {
    return this.getChildPropertyNames(purpose).map(propertyName =>
      loadNavigationProperty(propertyName as keyof Entity)
    );
  }
}
