import { Directive, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BreezePropertyConfig } from './breeze-property-config';

@Directive({
  selector: '[breezePropertyOptions]'
})
export class BreezePropertyOptionsDirective {
  private value = new ReplaySubject<Partial<BreezePropertyConfig>>(1);
  value$ = this.value.asObservable();
  @Input() set breezePropertyOptions(options: Partial<BreezePropertyConfig>) {
    this.value.next(options);
  }
}
