import { BaseEntity } from '../base-entity';
import { Role } from './role';
import { UserPrincipal } from './user-principal';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { AutoGeneratedKeyType, MetadataStore } from 'breeze-client';
/// </code-import>

export interface UserRole {
  roleId: string;
  userId: string;
  tenantKey: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  role: Role;
  user: UserPrincipal;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class UserRole extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization = AuthzContextsActionMap.crudFor(EntityName.UserRole);

  static getKeyValues(role: Role, user: UserPrincipal, currentTenantKey: string) {
    return [role.id, user.id, UserRole.resolveTenantKey(role, currentTenantKey)];
  }

  static getKeyValuesMap(role: Role, user: UserPrincipal, currentTenantKey: string) {
    return {
      roleId: role.id,
      userId: user.id,
      tenantKey: UserRole.resolveTenantKey(role, currentTenantKey)
    };
  }

  static register(metadataStore: MetadataStore) {
    // for some reason the metadata `autoGeneratedKeyType` being generated from the EF Core model is wrong.
    // Setting it here to what it should be.
    // Hopefully this gets fixed in a future release of the c# generator code (BreezePersistenceManager.Metadata() method)
    const et = metadataStore.getAsEntityType('UserRole', false);
    et.setProperties({ autoGeneratedKeyType: AutoGeneratedKeyType.None });
  }

  static resolveTenantKey(role: Partial<Role>, currentTenantKey: string): string {
    return role.isGlobal ? '' : currentTenantKey;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
