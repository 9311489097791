/* eslint-disable @typescript-eslint/no-this-alias */
import { Injectable, Injector, OnDestroy, Optional, SkipSelf, Type } from '@angular/core';
import {
  SaveOptions as BreezeSaveOptions,
  Entity,
  EntityAction,
  EntityManager,
  EntityState,
  ExpandClause,
  FetchStrategy,
  MergeStrategy,
  SaveResult
} from 'breeze-client';
import { HasEntityGraph } from 'breeze-client/mixin-get-entity-graph';
import { ImportConfig, QueryErrorCallback, QuerySuccessCallback } from 'breeze-client/src/entity-manager';
import { EntityQuery } from 'breeze-client/src/entity-query';
import { SaveOptionsConfig } from 'breeze-client/src/save-options';
import partition from 'lodash-es/partition';
import { BehaviorSubject, merge, Observable, scan, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMapTo,
  withLatestFrom
} from 'rxjs/operators';
import { getUntrackedEntityValidationErrors, isBreezeServerSaveError } from './breeze-error';
import {
  BreezeNotification,
  BreezeNotificationOptions,
  FetchSuccessNotification,
  ImportNotification,
  notificationFor,
  notificationOfType,
  notificationOfTypes,
  RollbackNotification,
  SaveCancelledNotification,
  SaveErrorNotification,
  SaveNotification,
  saveNotifications,
  SaveSuccessNotification
} from './breeze-notification';
import { copyEntities, CopyEntitiesConfig, copyEntityMap } from './copy-entities';
import { DefaultEntity } from './default-entity';
import { EntityManagerFactoryService } from './entity-manager-factory.service';
import {
  fromEntityChangeEvent,
  fromHasChangesChangedEvent,
  fromValidationErrorsChangedEvent
} from './from-breeze-event';
import {
  getAllKeyedValidationErrors,
  isKeyedValidationErrorsEqual,
  KeyedValidationError
} from './keyed-validation-error';
import { pluckPath } from './pluck-path';
import { GraphLoadPurpose } from './queries';
import {
  isQueryHistoryEntryEquivalentTo,
  normalizeEntityQueryDto,
  QueryHistoryEntry,
  toEntityQueryKey
} from './query-history';
import { RepositoryService } from './repository.service';
import { isEntity } from './type-guard-functions';
import { InternalUnitOfWorkNotificationService } from './unit-of-work-notification.service';

type EntityMap<T> = Record<keyof T, Entity | Entity[]>;

const canRollback = (saving: boolean, isModified: boolean) => !saving && isModified;
const canSave = (saving: boolean, isModified: boolean, isValid: boolean) => !saving && isModified && isValid;

type PatchedEntityManager = HasEntityGraph & {
  __unpatch(): void;
};

export interface SaveOptions extends SaveOptionsConfig {
  /**
   * When a new entity is persisted should it's navigation properties be considered as having been loaded?
   * The default is `true`
   * */
  markNewNavPropsAsLoaded: boolean;
  /**
   * When the state of a new entity that has been persisted is merged to the parent unit of work,
   * should it's navigation properties be considered as having been loaded? The default is `true`
   * <p>Note: this only takes affect when `updateParentUnitOfWork` is set to `true`</p>
   * */
  markNewNavPropsAsLoadedInParentUnitOfWork: boolean;
  /**
   * Should the state of the entities after they are saved, be merged to the unit of work that is parent of the unit of
   * work that performed the save? The default is `false`
   * */
  updateParentUnitOfWork: boolean;

  /**
   * Should the state of the entities after they are saved, be merged to all the way to the top level unit of work
   * This provides the ability to update all hierarchical parent unit's of work without needing to know at which depth the current
   * uow is sitting. The default is `false`
   * */
  updateAncestorUnitOfWork: boolean;

  /**
   * Should the save be committed in memory immediately and the actual save to the server be performed in the background?
   * The default is `false`
   * */
  backgroundSave: boolean | BackgroundSaveOptions;

  /**
   * A function that accepts an entity which determines whether or not the entity should be excluded from the saved to server
   * If returns true then acceptChanges is applied to the entity, preventing it from being saved to the server
   */
  shouldNotSaveToServer?: (checkEntity: Entity) => boolean;
}

export interface BackgroundSaveOptions {
  errorHandler?: (err: unknown) => Promise<unknown> | undefined;
  /**
   * Should the state of the entities returned from the server, be merged to the unit of work? The default is `false`
   *
   * The unit of work that will receive entities from the server are as follows:
   * 1. The unit of work that initiated the background save, but only if that unit of work is not disposed already
   * 2. `SaveOptions.updateParentUnitOfWork` equals `true`: the unit of work that is parent of the unit of
   *    work that initiated the background save
   * 3. `SaveOptions.updateAncestorUnitOfWork` equals `true`: the unit of work that is parent, and all unit of works
   *    above it up to the root unit of work
   */
  updateUnitOfWorkWithServerResponse?: boolean;
}

export const defaultSaveOptions: SaveOptions = {
  markNewNavPropsAsLoaded: true,
  markNewNavPropsAsLoadedInParentUnitOfWork: true,
  updateParentUnitOfWork: false,
  updateAncestorUnitOfWork: false,
  backgroundSave: false
};

export interface UnitOfWorkState {
  canRollback: boolean;
  canSave: boolean;
  isChanged: boolean;
  isClientValid: boolean;
  isDirty: boolean;
  isServerValid: boolean;
  queryHistory: QueryHistoryEntry[];
  saveState: SaveState;
  validationErrors: KeyedValidationError[];
}

export interface SaveState {
  errored: boolean;
  started: boolean;
  succeeded: boolean;
}

export const hasSaveProgress = (saveState: SaveState) => saveState.errored || saveState.started || saveState.succeeded;

export const initialSaveState: SaveState = Object.freeze({
  errored: false,
  started: false,
  succeeded: false
});

function patchState<T, S extends Partial<UnitOfWorkState>>(stateUpdate: (evt: T) => S) {
  return (source$: Observable<T>): Observable<S> => source$.pipe(map(stateUpdate));
}

const noopSaveResults = (): SaveResult => ({ entities: [], keyMappings: [] });

@Injectable({ providedIn: 'root' })
export class UnitOfWorkService implements OnDestroy {
  readonly level: number = 0;
  readonly canRollback$: Observable<boolean>;
  readonly canSave$: Observable<boolean>;
  readonly queryHistory$: Observable<QueryHistoryEntry[]>;
  readonly hasSaveError$: Observable<boolean>;
  readonly isDirty$: Observable<boolean>;
  readonly isChanged$: Observable<boolean>;
  readonly isClientValid$: Observable<boolean>;
  readonly isSaving$: Observable<boolean>;
  readonly isServerValid$: Observable<boolean>;
  private _manager: PatchedEntityManager;
  get manager(): HasEntityGraph {
    return this._manager;
  }
  readonly saveState$: Observable<SaveState>;
  readonly state$: Observable<UnitOfWorkState>;
  readonly validationErrors$: Observable<KeyedValidationError[]>;

  private readonly _parent: UnitOfWorkService | undefined;
  private isDestroyed = false;
  private registeredRepos = new Map<Type<RepositoryService>, RepositoryService>();
  private subscription = new Subscription();
  private unitOfWorkState: BehaviorSubject<UnitOfWorkState>;

  constructor(
    private injector: Injector,
    private emFactory: EntityManagerFactoryService,
    private notificationService: InternalUnitOfWorkNotificationService,
    @SkipSelf() @Optional() parent?: UnitOfWorkService
  ) {
    if (parent) {
      this.level = parent.level + 1;
    }
    this._parent = parent;

    this._manager = this.createEntityManager();

    const initialState: UnitOfWorkState = {
      canRollback: false,
      canSave: false,
      isChanged: false,
      isClientValid: true,
      isDirty: this.manager.hasChanges(),
      isServerValid: false,
      queryHistory: [],
      saveState: initialSaveState,
      validationErrors: []
    };
    this.unitOfWorkState = new BehaviorSubject<UnitOfWorkState>(initialState);
    this.state$ = this.unitOfWorkState.asObservable();

    this.canRollback$ = this.selectState('canRollback');
    this.canSave$ = this.selectState('canSave');
    this.hasSaveError$ = this.selectState('saveState', 'errored');
    this.isDirty$ = this.selectState('isDirty');
    this.isClientValid$ = this.selectState('isClientValid');
    this.isChanged$ = this.selectState('isChanged');
    this.isSaving$ = this.selectState('saveState', 'started');
    this.isServerValid$ = this.selectState('isServerValid');
    this.saveState$ = this.selectState('saveState');
    this.queryHistory$ = this.selectState('queryHistory');
    this.validationErrors$ = this.selectState('validationErrors');

    const isChangedStateChange$ = this.createIsChanged$().pipe(
      patchState(isChanged => ({
        isChanged,
        canRollback: canRollback(this.isSaving, isChanged),
        canSave: canSave(this.isSaving, isChanged, this.isClientValid)
      }))
    );

    const isDirtyStateChange$ = fromHasChangesChangedEvent(this.manager).pipe(
      patchState(evt => ({
        isChanged: this.unitOfWorkState.value.isChanged && evt.hasChanges,
        isDirty: evt.hasChanges,
        canRollback: canRollback(this.isSaving, evt.hasChanges),
        canSave: canSave(this.isSaving, evt.hasChanges, this.isClientValid)
      }))
    );

    const queryHistoryChanges$ = this.createQueryHistory$(initialState.queryHistory).pipe(
      patchState(queryHistory => ({ queryHistory }))
    );

    const saveStateChange$ = this.notificationService.notifications$.pipe(
      notificationFor(this),
      saveNotifications(),
      patchState(evt => ({
        saveState: { errored: evt.error, started: evt.start, succeeded: evt.success },
        canRollback: canRollback(evt.start, this.isChanged),
        canSave: canSave(evt.start, this.isChanged, this.isClientValid)
      }))
    );

    const validationErrorStateChange$ = this.createValidationErrors$(initialState.validationErrors).pipe(
      patchState(validationErrors => {
        const [serverErrors, clientErrors] = partition(validationErrors, e => e.error.isServerError);
        const isClientValid = clientErrors.length === 0;
        const isServerValid = serverErrors.length === 0;
        return {
          validationErrors,
          isClientValid,
          isServerValid,
          canSave: canSave(this.isSaving, this.isChanged, isClientValid)
        };
      })
    );

    const stateChange$ = merge(
      isDirtyStateChange$,
      isChangedStateChange$,
      queryHistoryChanges$,
      saveStateChange$,
      validationErrorStateChange$
    );
    const sub = stateChange$.subscribe(newState => {
      this.updateState(newState);
    });
    this.subscription.add(sub);
  }

  private createQueryHistory$(initialState: QueryHistoryEntry[]) {
    return this.notificationsOfType$(FetchSuccessNotification).pipe(
      map(({ result }) => result.query),
      filter((query): query is EntityQuery => typeof query !== 'string'),
      map((query): QueryHistoryEntry => {
        const normalizedEq = normalizeEntityQueryDto(query);
        return {
          query: normalizedEq,
          queryKey: toEntityQueryKey(normalizedEq),
          completedOn: new Date().toISOString()
        };
      }),
      scan((acc, entry) => [...acc, entry], initialState)
    );
  }

  private createEntityManager(): PatchedEntityManager {
    const entityManager = this.emFactory.newManager();
    // we're monkey patching EntityManager because we don't have access to modify all callers of executeQuery to call
    // an equivalent method on our class
    const { patchedExecuteQuery, originalExecuteQuery } = this.createNotifyingExecuteQueryMethod(entityManager, this);
    return Object.assign(entityManager, {
      executeQuery: patchedExecuteQuery.bind(entityManager),
      __unpatch() {
        entityManager.executeQuery = originalExecuteQuery;
      }
    });
  }

  get canRollback() {
    return this.unitOfWorkState.value.canRollback;
  }

  get canSave() {
    return this.unitOfWorkState.value.canSave;
  }

  get hasParent() {
    return this._parent != null;
  }

  get hasSaveError() {
    return this.unitOfWorkState.value.saveState.errored;
  }

  get isChanged() {
    return this.unitOfWorkState.value.isChanged;
  }

  get isClientValid() {
    return this.unitOfWorkState.value.isClientValid;
  }

  get isDirty() {
    return this.unitOfWorkState.value.isDirty;
  }

  get isSaving() {
    return this.unitOfWorkState.value.saveState.started;
  }

  get isServerValid() {
    return this.unitOfWorkState.value.isServerValid;
  }

  get queryHistory() {
    return this.unitOfWorkState.value.queryHistory;
  }

  get saveState() {
    return this.unitOfWorkState.value.saveState;
  }

  get validationErrors() {
    return this.unitOfWorkState.value.validationErrors;
  }

  /** Return the parent unit of work in the hierarchy */
  get parent(): UnitOfWorkService {
    if (!this._parent) {
      throw new Error(
        "Tried to access parent unit of work when there is no parent. To avoid receiving an exception, guard the property access using 'hasParent'"
      );
    }
    return this._parent;
  }

  /**
   * Return the top most unit of work in the hierarchy, which will be this instance if there is no parent.
   * Use this property instead of explicitly chaining `parent` properties when:
   * 1. You know you want to work with entities that have been / need to be loaded in the top level unit of work.
   *    For example by a route resolver or a component associated with a top-level route is used to load entities AND
   * 2. You don't know, or don't want to be explicitly coupled to how many levels of unit of work instances there are in
   *    the hierarchy
   * */
  get root(): UnitOfWorkService {
    let current: UnitOfWorkService;
    current = this;

    while (current.hasParent) {
      current = current.parent;
    }
    return current;
  }

  get state() {
    return this.unitOfWorkState.value;
  }

  /** @internal */
  ngOnDestroy(): void {
    if (this.isDestroyed) return;

    this.isDestroyed = true;
    if (this.isSaving) {
      this.notificationService.publish(new SaveCancelledNotification({ uow: this }));
    }
    this._manager.__unpatch();
    this.subscription.unsubscribe();
  }

  exportChangesToParent() {
    if (!this.manager.hasChanges()) return;

    const entities = this.manager.getChanges();
    this.exportToParent(entities, { mergeStrategy: MergeStrategy.OverwriteChanges, mergeAdds: true });
    this.manager.acceptChanges();
  }

  exportEntitiesToParent(entities: Entity[]) {
    this.exportToParent(entities, { mergeStrategy: MergeStrategy.OverwriteChanges, mergeAdds: true });
    entities
      .filter(e => !e.entityAspect.entityState.isDetached())
      .forEach(e => {
        e.entityAspect.acceptChanges();
      });
  }

  exportToParent<T extends Entity>(entity: T, importOptions?: ImportConfig): T;
  exportToParent<T extends Entity>(entities: T[], importOptions?: ImportConfig): T[];
  exportToParent<T extends EntityMap<T>>(sandboxMap: T, importOptions?: ImportConfig): T;
  exportToParent<T extends Entity, U extends EntityMap<U>>(
    entityOrEntitiesOrEntityMap: T | T[] | U,
    importOptions?: ImportConfig
  ): T | T[] | U {
    return this.exportToUnitOfWork<T, U>(this.manager, this.parent, entityOrEntitiesOrEntityMap, importOptions);
  }

  private exportToUnitOfWork<T extends Entity, U extends EntityMap<U>>(
    sourceManager: EntityManager,
    targetUow: UnitOfWorkService,
    entityOrEntitiesOrEntityMap: T | T[] | U,
    importOptions?: ImportConfig
  ): T | T[] | U {
    const copyOptions: CopyEntitiesConfig = { ...importOptions, mergeDetachedAs: EntityState.Detached };
    const results: T | T[] | U =
      Array.isArray(entityOrEntitiesOrEntityMap) || isEntity(entityOrEntitiesOrEntityMap)
        ? copyEntities(sourceManager, targetUow.manager, entityOrEntitiesOrEntityMap, copyOptions)
        : copyEntityMap(sourceManager, targetUow.manager, entityOrEntitiesOrEntityMap, copyOptions);

    const notification = new ImportNotification({ uow: targetUow, isSandbox: false });
    targetUow.notificationService.publish(notification);
    return results;
  }

  /**
   Get related entities of root entity as specified by the default property paths returned by calling
   `getPropertyPathsFor(GraphLoadPurpose.View)` method on the supplied root entity.
   @example
   // assume that `customer.getPropertyPathsFor` returns 'Orders.OrderDetails'
   var associations = uow.getEntityAssociations(customer);
   // associations will be all of the customer's orders and their details even if deleted.
   @param rootEntity The root entity.
   @return related entities, including deleted entities. Duplicates are removed and entity order is indeterminate.
   **/
  getEntityAssociations<T extends DefaultEntity = DefaultEntity>(rootEntity: T): Entity[];
  /**
   Get related entities of root entity (or root entities) as specified by expand.
   @example
   var associations = uow.getEntityAssociations(customer, 'Orders.OrderDetails');
   // associations will be all of the customer's orders and their details even if deleted.
   @param roots The root entity or root entities.
   @param expand An expand string, a query expand clause, or array of string paths
   @return related entities, including deleted entities. Duplicates are removed and entity order is indeterminate.
   **/
  getEntityAssociations<T extends Entity = Entity>(roots: T | T[], expand: string | string[] | ExpandClause): Entity[];
  getEntityAssociations<T extends Entity = Entity>(
    roots: T | T[],
    expand?: string | string[] | ExpandClause
  ): Entity[] {
    const rootsList: Entity[] = (Array.isArray(roots) ? roots : [roots]).filter(
      e => e.entityAspect.entityState !== EntityState.Detached
    );
    if (rootsList.length === 0) {
      return [];
    }

    expand = expand ?? (rootsList[0] as DefaultEntity).getPropertyPathsFor(GraphLoadPurpose.View);
    const entityGraph = this.manager.getEntityGraph(roots, expand);
    return entityGraph.filter(e => !rootsList.includes(e));
  }

  isAncestorOfOrSelf(other: UnitOfWorkService) {
    if (other.level < this.level) return false;

    for (const uow of other.chain()) {
      if (uow === this) {
        return true;
      }
    }
    return false;
  }

  hasQueryHistoryFor(eg: EntityQuery) {
    return this.queryHistory.filter(isQueryHistoryEntryEquivalentTo(eg)).length > 0;
  }

  reset() {
    this._manager.__unpatch();
    this._manager = this.createEntityManager();
  }

  async sandboxQueryResult<T extends Entity>(query: (parentUow: UnitOfWorkService) => Promise<T>): Promise<T>;
  async sandboxQueryResult<T extends Entity>(query: (parentUow: UnitOfWorkService) => Promise<T[]>): Promise<T[]>;
  async sandboxQueryResult<T extends Entity>(
    query: (parentUow: UnitOfWorkService) => Promise<T | T[]>
  ): Promise<T | T[]> {
    const results: Entity | Entity[] = await query(this.parent);
    return this.sandbox(results) as T | T[];
  }

  sandbox<T extends Entity>(entity: T): T;
  sandbox<T extends Entity>(entities: T[]): T[];
  sandbox<T extends EntityMap<T>>(sandboxMap: T): T;
  sandbox<T extends Entity, U extends EntityMap<U>>(entityOrEntitiesOrEntityMap: T | T[] | U): T | T[] | U {
    const copyOptions = { mergeDetachedAs: EntityState.Added };
    const results =
      Array.isArray(entityOrEntitiesOrEntityMap) || isEntity(entityOrEntitiesOrEntityMap)
        ? copyEntities(this.parent.manager, this.manager, entityOrEntitiesOrEntityMap, copyOptions)
        : copyEntityMap(this.parent.manager, this.manager, entityOrEntitiesOrEntityMap, copyOptions);

    const notification = new ImportNotification({ uow: this, isSandbox: true });
    this.notificationService.publish(notification);
    return results;
  }

  notifications$(options?: BreezeNotificationOptions) {
    return this.notificationService.notifications$.pipe(
      notificationFor(this, options),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  notificationsOfType$<T extends BreezeNotification>(type: Type<T>, options?: BreezeNotificationOptions) {
    return this.notificationService.notifications$.pipe(
      notificationFor(this, options),
      notificationOfType(type),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  notificationsOfTypes$(types: Type<BreezeNotification>[], options?: BreezeNotificationOptions) {
    return this.notificationService.notifications$.pipe(
      notificationFor(this, options),
      notificationOfTypes(types),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  registerRepository<T extends RepositoryService>(instance: T, type: Type<T>) {
    this.registeredRepos.set(type, instance);
  }

  repo<T extends RepositoryService>(type: Type<T>): T {
    return (this.registeredRepos.get(type) as T) ?? this.injector.get<T>(type);
  }

  rollback(): void {
    if (!this.manager.hasChanges()) return;

    this.manager.rejectChanges();
    this.notificationService.publish(new RollbackNotification({ uow: this }));
  }

  async saveChanges<T extends Entity>(entity: T, options?: Partial<SaveOptions>): Promise<SaveResult>;
  async saveChanges<T extends Entity>(entities: T[], options?: Partial<SaveOptions>): Promise<SaveResult>;
  async saveChanges(options?: Partial<SaveOptions>): Promise<SaveResult>;
  async saveChanges<T extends Entity, S extends Partial<SaveOptions>>(
    entityOrEntitiesOrSaveOptions: T | T[] | S,
    options?: Partial<SaveOptions>
  ): Promise<SaveResult> {
    let changes: Entity[];

    if (Array.isArray(entityOrEntitiesOrSaveOptions)) {
      changes = entityOrEntitiesOrSaveOptions;
    } else if (isEntity(entityOrEntitiesOrSaveOptions)) {
      changes = [entityOrEntitiesOrSaveOptions];
    } else {
      if (!this.manager.hasChanges()) return noopSaveResults();

      changes = this.manager.getChanges();
      options = entityOrEntitiesOrSaveOptions;
    }

    if (changes.length === 0) return noopSaveResults();

    this.notificationService.publish(new SaveNotification({ changes, uow: this }));

    const finalOptions: SaveOptions = options ? { ...defaultSaveOptions, ...options } : defaultSaveOptions;
    const {
      markNewNavPropsAsLoaded,
      markNewNavPropsAsLoadedInParentUnitOfWork,
      updateParentUnitOfWork,
      updateAncestorUnitOfWork,
      backgroundSave,
      shouldNotSaveToServer,
      ...saveOptions
    } = finalOptions;

    const newEntities = changes.filter(x => x.entityAspect.entityState === EntityState.Added);

    try {
      const { locallyDeleted, sendToServer } = this.commitClientsideOnlyChanges(changes, shouldNotSaveToServer);

      let saveResults = !backgroundSave
        ? await this.manager.saveChanges(sendToServer, new BreezeSaveOptions(saveOptions))
        : this.backgroundSave(sendToServer, finalOptions);

      if (locallyDeleted.length > 0)
        saveResults = this.addLocallyDeletedEntitiesToSaveResults(saveResults, locallyDeleted);

      // check is save has already been cancelled (eg this service has been disposed), and if so we want to
      // ignore the response (success or failure) from the server. Note: we are NOT cancelling the server request
      // as breeze does not make this easy; if we really need to cancel the server request then we need to add
      // an http interceptor to get a handle on the HttpClient request
      if (!this.isSaving) {
        return noopSaveResults();
      }

      if (markNewNavPropsAsLoaded) {
        this.markNavigationPropertiesAsLoaded(newEntities);
      }

      if (updateAncestorUnitOfWork) {
        this.updateAncestorUnitOfWork(saveResults, markNewNavPropsAsLoadedInParentUnitOfWork ? newEntities : []);
      } else if (updateParentUnitOfWork) {
        this.updateParentUnitOfWork(saveResults, markNewNavPropsAsLoadedInParentUnitOfWork ? newEntities : []);
      }
      this.notificationService.publish(SaveSuccessNotification.from(saveResults, this));
      return saveResults;
    } catch (error) {
      if (!this.isSaving) {
        // see note above
        return noopSaveResults();
      }
      this.notificationService.publish(new SaveErrorNotification({ error, uow: this }));
      throw error;
    }
  }

  private addLocallyDeletedEntitiesToSaveResults(saveResults: SaveResult, deleted: Entity[]): SaveResult {
    const deletedKeys = saveResults.deletedKeys ?? [];
    const locallyDeletedKeys = deleted.map(entity => ({
      entityTypeName: entity.entityType.name,
      keyValues: entity.entityAspect.getKey().values
    }));

    return {
      ...saveResults,
      deletedKeys: [...deletedKeys, ...locallyDeletedKeys],
      entities: [...saveResults.entities, ...deleted]
    };
  }

  private backgroundSave(entities: Entity[], { backgroundSave, ...otherOptions }: SaveOptions): SaveResult {
    const { updateAncestorUnitOfWork, updateParentUnitOfWork } = otherOptions;
    const backgroundSaveOptions = typeof backgroundSave === 'object' ? backgroundSave : {};
    const { errorHandler, updateUnitOfWorkWithServerResponse } = backgroundSaveOptions;

    // copy entities into isolated EntityManager
    const backgroundManager = this.manager.createEmptyCopy();
    copyEntities(this.manager, backgroundManager, entities);

    this.manager.acceptChanges(); // pretend they're already saved to the server

    // save changes to server and hope it works!
    // note: this is a "fire-and-forget" operation therefore we do NOT await or return the resulting promise
    // todo: make this robust! ie implement a background queue
    const futureResult = backgroundManager.saveChanges();
    if (updateUnitOfWorkWithServerResponse) {
      futureResult.then(saveResults => {
        if (!this.isDestroyed) {
          this.updateUnitOfWork(backgroundManager, this, saveResults);
        }
        if (updateAncestorUnitOfWork) {
          this.updateAncestorUnitOfWork(saveResults);
        } else if (updateParentUnitOfWork) {
          this.updateParentUnitOfWork(saveResults);
        }
      }, errorHandler);
    } else {
      futureResult.catch(errorHandler);
    }

    return {
      entities,
      keyMappings: []
    };
  }

  private *chain() {
    let current: UnitOfWorkService | undefined = this;
    while (current) {
      yield current;
      current = this.parent;
    }
  }

  private commitClientsideOnlyChanges(changes: Entity[], shouldNotSaveToServer?: (checkEntity: Entity) => boolean) {
    if (typeof shouldNotSaveToServer !== 'function') {
      return {
        locallyDeleted: [],
        sendToServer: changes
      };
    }

    const entitiesNotToSave = changes.filter(shouldNotSaveToServer);
    const locallyDeleted = entitiesNotToSave.filter(x => x.entityAspect.entityState.isDeleted());
    entitiesNotToSave.forEach(entity => {
      entity.entityAspect.acceptChanges();
    });
    // note: although we are sending modified and new entities as unchanged to the server (whether we should is another question!),
    // we can't do that for deleted entities. if we try that causes an exception to be thrown by client-side breeze
    // library
    const sendToServer = changes.filter(x => !locallyDeleted.includes(x));
    return {
      locallyDeleted,
      sendToServer
    };
  }

  private createIsChanged$() {
    const entityChange$ = fromEntityChangeEvent(this.manager).pipe(
      filter(evt => {
        return (
          evt.entityAction === EntityAction.PropertyChange ||
          (evt.entityAction === EntityAction.EntityStateChange &&
            (evt.entityAction.isModification() || evt.entity?.entityAspect.entityState.isAddedModifiedOrDeleted())) ||
          (evt.entityAction === EntityAction.AttachOnImport &&
            (evt.entity?.entityAspect.entityState.isAdded() || evt.entity?.entityAspect.entityState.isModified())) ||
          evt.entityAction.isDetach()
        );
      }),
      mapTo(true),
      startWith(false)
    );

    const sandboxImport$ = this.notificationsOfType$(ImportNotification).pipe(filter(evt => evt.isSandbox));

    const sessionStart$ = merge(
      this.notificationsOfTypes$([RollbackNotification, SaveSuccessNotification]),
      sandboxImport$
    ).pipe(mapTo(0), startWith(0));

    return sessionStart$.pipe(switchMapTo(entityChange$), distinctUntilChanged());
  }

  private createUntrackedEntityValidationErrors$(): Observable<KeyedValidationError[]> {
    const modelValidationStatusCodes = [400, 422];
    const errors$ = this.notificationsOfType$(SaveErrorNotification).pipe(
      map(notification => {
        const error = notification.error;
        if (!isBreezeServerSaveError(error) || !modelValidationStatusCodes.includes(error.status)) {
          return [];
        } else {
          return getUntrackedEntityValidationErrors(error, notification.uow.manager);
        }
      })
    );
    const errorsReset$ = this.notificationsOfType$(SaveNotification).pipe(mapTo([]));
    return merge(errors$, errorsReset$).pipe(startWith([]), shareReplay({ refCount: true, bufferSize: 1 }));
  }

  private createValidationErrors$(initialValue: KeyedValidationError[]): Observable<KeyedValidationError[]> {
    // note: validation errors tracked by an entity WILL be returned here, even if the server response has a status code
    // of 500 (say). This isn't ideal as they aren't really validation that user input is good, but more like an problem
    // with the server
    const untrackedValidationErrors$ = this.createUntrackedEntityValidationErrors$();
    const entityDetaches$ = fromEntityChangeEvent(this.manager).pipe(
      filter(evt => evt.entityAction === EntityAction.Detach)
    );
    const refreshValidationErrors$ = merge(
      fromValidationErrorsChangedEvent(this.manager),
      entityDetaches$,
      untrackedValidationErrors$
    );

    return refreshValidationErrors$.pipe(
      withLatestFrom(untrackedValidationErrors$),
      map(([_, untrackedServerErrors]) => getAllKeyedValidationErrors(this.manager).concat(untrackedServerErrors)),
      startWith(initialValue),
      distinctUntilChanged(isKeyedValidationErrorsEqual)
    );
  }

  private createNotifyingExecuteQueryMethod(entityManager: EntityManager, uow: this) {
    const originalExecuteQuery = entityManager.executeQuery;
    const patchedExecuteQuery = (
      query: EntityQuery | string,
      callback?: QuerySuccessCallback,
      errorCallback?: QueryErrorCallback
    ) => {
      return originalExecuteQuery.call(
        entityManager,
        query as EntityQuery,
        result => {
          if (typeof query === 'string' || query.queryOptions?.fetchStrategy !== FetchStrategy.FromLocalCache) {
            const notification = new FetchSuccessNotification({ uow, result });
            uow.notificationService.publish(notification);
          }
          if (callback) callback(result);
        },
        errorCallback
      );
    };
    return {
      originalExecuteQuery,
      patchedExecuteQuery
    };
  }

  private markNavigationPropertiesAsLoaded(entities: Entity | Entity[]) {
    (Array.isArray(entities) ? entities : [entities]).forEach(entity => {
      entity.entityType.navigationProperties.forEach(np => {
        entity.entityAspect.markNavigationPropertyAsLoaded(np);
      });
    });
  }

  private selectState<K1 extends keyof UnitOfWorkState>(k1: K1): Observable<UnitOfWorkState[K1]>;
  private selectState<K1 extends keyof UnitOfWorkState, K2 extends keyof UnitOfWorkState[K1]>(
    k1: K1,
    k2: K2
  ): Observable<UnitOfWorkState[K1][K2]>;
  private selectState<R extends never>(...properties: string[]): Observable<R> {
    return this.unitOfWorkState.pipe(
      pluckPath<R>(properties),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private updateAncestorUnitOfWork(saveResults: SaveResult, entitiesToMarkAsLoaded: Entity[] = []) {
    let current: UnitOfWorkService;
    current = this;
    do {
      current.updateParentUnitOfWork(saveResults, entitiesToMarkAsLoaded);
    } while (current.parent.hasParent && (current = current.parent));
  }

  private updateParentUnitOfWork(saveResults: SaveResult, entitiesToMarkAsLoaded: Entity[] = []) {
    this.updateUnitOfWork(this.manager, this.parent, saveResults, entitiesToMarkAsLoaded);
  }

  private updateUnitOfWork(
    sourceManager: EntityManager,
    uow: UnitOfWorkService,
    saveResults: SaveResult,
    entitiesToMarkAsLoaded: Entity[] = []
  ) {
    // see here for explanation of this code block: http://breeze.github.io/doc-cool-breezes/import-save-results.html
    const [deletes, keepers] = partition(saveResults.entities, e => e.entityAspect.entityState.isDetached());
    deletes.forEach(detached => {
      const entity = uow.manager.getEntityByKey(detached.entityAspect.getKey());
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      entity && entity.entityAspect.setDetached();
    });
    this.exportToUnitOfWork(sourceManager, uow, keepers);

    const markThese = entitiesToMarkAsLoaded
      .map(e => uow.manager.getEntityByKey(e.entityAspect.getKey()))
      .filter((e): e is Entity => !!e);
    this.markNavigationPropertiesAsLoaded(markThese);
  }

  private updateState(newState: Partial<UnitOfWorkState>) {
    this.unitOfWorkState.next({ ...this.unitOfWorkState.value, ...newState });
  }
}
