import { Injectable } from '@angular/core';
import { DataService, EntityManager, MetadataStore } from 'breeze-client';
import { HasEntityGraph, mixinEntityGraph } from 'breeze-client/mixin-get-entity-graph';
import { BreezeLibraryConfig } from './breeze-library-config';
import { patchEntityManager } from './patch-entity-manager';

mixinEntityGraph(EntityManager);
patchEntityManager(EntityManager);

@Injectable({ providedIn: 'root' })
export class EntityManagerFactoryService {
  masterManager: HasEntityGraph;

  constructor(config: BreezeLibraryConfig, metadataStore: MetadataStore) {
    const dataService = new DataService({
      ...config,
      hasServerMetadata: false
    });

    this.masterManager = new EntityManager({ dataService, metadataStore }) as HasEntityGraph;
  }

  newManager(): HasEntityGraph {
    return this.masterManager.createEmptyCopy() as HasEntityGraph;
  }
}
