import { BaseEntity } from '../base-entity';
import { OrganisationWorkspaceDeployment } from './organisation-workspace-deployment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface PbiDeployment {
  id: string;
  createdDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  orchestratorInstanceId?: string;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  triggerType: string;
  workspaceDeployments: OrganisationWorkspaceDeployment[];
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class PbiDeployment extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
