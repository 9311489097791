import { EntityQuery } from 'breeze-client';
import { TemplateWorkspace } from '../../models';

const baseQuery = EntityQuery.from('TemplateWorkspaces');
const withImage = baseQuery.expand(TemplateWorkspace.propName('icon'));

export const templateWorkspaceQueries = {
  selectAll: baseQuery,
  selectWithImage: withImage
};
