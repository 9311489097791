import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { ReportActivityRecord } from '../models';
import { reportActivityRecordQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class ReportActivityRecordRepositoryService extends RepositoryService<ReportActivityRecord> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, reportActivityRecordQueries.selectAll);
  }
}
