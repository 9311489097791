import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags
/* eslint-disable @typescript-eslint/no-explicit-any */
/// </code-import>

export interface SQLCache {
  id: string;
  absoluteExpiration?: Date;
  expiresAtTime: Date;
  slidingExpirationInSeconds?: number;
  value: any;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SQLCache extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
