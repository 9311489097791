import { MetadataStore, Validator } from 'breeze-client';

//NOTE:Ensure the Regex is maintained in sync with the server-side, which can be found in ValidationConstants.cs file.
// see link https://github.com/MRI-Software/data-services-gateway/blob/master/src/DataServicesGateway.Shared/Model/ValidationConstants.cs for more information
export const validModuleCodeRegEx = Validator.makeRegExpValidator(
  'validModuleCode',
  /^[a-zA-Z]+(,[a-zA-Z]+)*$/,
  "'%displayName%' should be null or comma separated values"
);

export const registerSharedValidators = (_: MetadataStore) => {
  Validator.register(validModuleCodeRegEx);
};
