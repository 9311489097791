import { Entity, EntityKey } from 'breeze-client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEntity(value: any): value is Entity {
  return value != null && value.entityAspect != null && value.entityType != null;
}

export function isEntityOfType<T extends Entity>(type: { new (): T }, entity: Entity): entity is T {
  return entity.entityType.getCtor() === type;
}
export function isEntityOfTypeFunc<T extends Entity>(type: new () => T) {
  return (entity: Entity): entity is T => isEntityOfType(type, entity);
}

export function isEntityKeyOfType<T extends Entity>(type: { new (): T }, entityKey: EntityKey) {
  return entityKey.entityType.getCtor() === type;
}

export function isEntityKeyOfTypeFunc<T extends Entity>(type: new () => T) {
  return (entityKey: EntityKey) => isEntityKeyOfType(type, entityKey);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEntityArray(value: any): value is Array<Entity> {
  if (!Array.isArray(value)) return false;

  // only test the first element for performance reasons
  const [entity] = value;
  return entity === undefined || isEntity(entity);
}
