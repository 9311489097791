import {
  breezeNormalizer,
  notEmptyGuidValidator,
  notNullOrUndefinedValidator,
  patchBreezePropertySetter
} from '@mri/breeze-entity';
import { DataType, MetadataStore, Validator } from 'breeze-client';
import { BaseEntity } from '../base-entity';

export const registerModelConventions = (metadataStore: MetadataStore) => {
  const requiredValidator = Validator.required();
  const allDataProperties = metadataStore.getEntityTypes().flatMap(et => et.dataProperties);

  const guidDataProperties = allDataProperties.filter(p => p.dataType === DataType.Guid);
  guidDataProperties.forEach(p => {
    // our server rejects non-empty GUIDs by default... we should follow that convention on the client-side too
    // if it makes sense for your entity property to have an empty guid by default, for example when you know this
    // guid is assigned on the server-side, then you can remove this validator as per the example below
    p.validators = [...p.validators, notEmptyGuidValidator];
  });
  guidDataProperties.forEach(patchBreezePropertySetter(breezeNormalizer.Guid));

  // example of how to remove the notEmptyGuidValidator...
  // export class YourEntityClass extends BaseEntity {
  //   static register(metadataStore: MetadataStore) {
  //     const et = metadataStore.getAsEntityType('YourEntityClass', false);
  //     const p = et.getProperty('someProperty', true);
  //     p.validators = p.validators.filter(v => v !== notEmptyGuidValidator);
  //   }
  // }
  // IMPORTANT: make sure to register the custom metadata registration function in an appropriate module:
  // providers: [{ provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: YourEntityClass.register, multi: true }]

  allDataProperties
    .filter(p => p.name === 'tenantKey')
    .forEach(p => {
      p.defaultValue = BaseEntity.serverPlaceholderStringValue;
      p.validators = [
        // the built-in `required` validator is too restrictive as it does NOT allow an empty string
        notNullOrUndefinedValidator,
        ...p.validators.filter(v => v.name !== requiredValidator.name)
      ];
    });

  allDataProperties
    .filter(p => p.name === 'lastUpdatedBy')
    .forEach(p => {
      p.defaultValue = BaseEntity.serverPlaceholderStringValue;
      p.validators = [
        // the built-in `required` validator is too restrictive as it does NOT allow an empty string
        notNullOrUndefinedValidator,
        ...p.validators.filter(v => v.name !== requiredValidator.name)
      ];
    });
  allDataProperties
    .filter(p => p.name === 'lastUpdatedDate')
    .forEach(p => {
      p.defaultValue = new Date();
    });

  allDataProperties
    .filter(p => p.dataType === DataType.String)
    .forEach(patchBreezePropertySetter(breezeNormalizer.String));

  metadataStore.getEntityTypes().forEach(et => {
    // we're currently telling EF Core to treat properties as being a concurrency property so that EF Core
    // will add the original value to the WHERE clause of it's UPDATE and DELETE statements.
    // we're doing that for a security check to ensure the original values haven't been tampered with.
    // in other words we are NOT wanting breeze to bump the values of these properties as part of its in-built
    // concurrency control mechanism.
    // if/when we do want breeze to do that, then we should use a naming convention for these concurrency
    // properties and keep these in the `concurrencyProperties` below
    et.concurrencyProperties = [];
  });
};
