import { BaseEntity } from '../base-entity';
import { Report } from './report';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
/// </code-import>

export interface Bookmark {
  id: string;
  isDefault: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  periodEnd: Date;
  periodStart: Date;
  reportId: string;
  state: string;
  tenantKey: string;
  report: Report;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Bookmark extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.Bookmark),
    [CrudAction.Update]: AuthzContext.updateFor(
      ...BaseEntity.qualifiedPropNames<Bookmark>(EntityName.Bookmark, 'state', 'name', 'isDefault')
    )
  };

  static propName(name: keyof Bookmark) {
    return name;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
