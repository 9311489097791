import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface ForgottenUser {
  id: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  userId: string;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ForgottenUser extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
