import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Entity } from 'breeze-client';
import { EntityId, isEntityNewId } from './entity-id-functions';
import { RepositoryService } from './repository.service';

export interface ResolveContext {
  route: ActivatedRouteSnapshot;
  state: RouterStateSnapshot;
}

export interface EntityRouteResolverOptions {
  isNewParamName?: string;
  idParamName?: string;
  notFoundFallbackUrl: string | boolean;
}

const defaultOptions: Required<Pick<EntityRouteResolverOptions, 'idParamName' | 'isNewParamName'>> = {
  isNewParamName: 'isNew',
  idParamName: 'id'
};

@Injectable()
export class EntityResolver<T extends Entity> implements Resolve<T | undefined> {
  isEntityNewId = isEntityNewId;
  options: Required<EntityRouteResolverOptions>;

  constructor(protected repo: RepositoryService<T>, protected router: Router, options: EntityRouteResolverOptions) {
    this.options = { ...defaultOptions, ...options };
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<T | undefined> {
    const id = this.getId(route);

    if (this.isEntityNewId(id)) {
      return this.createEntity({ route, state });
    }

    const entity = await this.getEntity(id, this.getIsNew(route));
    if (!entity && typeof this.options.notFoundFallbackUrl === 'string') {
      await this.router.navigateByUrl(this.options.notFoundFallbackUrl);
      return;
    } else {
      return entity;
    }
  }

  protected createEntity(_context: ResolveContext): Promise<T> {
    // create the entity but do NOT attach it to the current entityManager; instead the target component being routed to
    // is expected to attach this entity to it's sand-boxed unit of work
    return this.repo.createEntity(this.getInitialEntityValues(_context), { attach: false });
  }

  protected getEntity(id: EntityId, isNew: boolean): Promise<T | undefined> {
    if (isNew) {
      return Promise.resolve(this.repo.getLocalEntityByKey(id) ?? undefined);
    } else {
      return this.repo.withId(id, { checkLocalCacheFirst: true, expands: this.getExpandPaths() });
    }
  }

  protected getExpandPaths(): Array<keyof T> | undefined {
    return undefined;
  }

  protected getId(route: ActivatedRouteSnapshot): EntityId {
    return route.paramMap.get(this.options.idParamName) || '';
  }

  protected getInitialEntityValues(_context: ResolveContext): Partial<T> {
    return {};
  }

  protected getIsNew(route: ActivatedRouteSnapshot): boolean {
    return !!route.paramMap.get(this.options.isNewParamName);
  }
}
