import { DataProperty, Entity } from 'breeze-client';
import { ValueNormalizerFactory } from './default-normalizers';

function getPropertySetterMonkeyPatchInfo(prop: DataProperty) {
  if (prop.parentType == undefined || !prop.isSettable) return;

  const entityClass = prop.parentType.getCtor();
  const entityProto = entityClass.prototype;
  const propDef = Object.getOwnPropertyDescriptor(entityProto, prop.name);
  if (!propDef || !propDef.configurable) return;

  return { entityProto, getter: propDef.get, setter: propDef.set };
}

export function patchBreezePropertySetter<T>(valueSelectorFactory: ValueNormalizerFactory<T>) {
  return (prop: DataProperty) => {
    const { getter, setter, entityProto } = getPropertySetterMonkeyPatchInfo(prop) ?? {};
    if (!getter || !setter || !entityProto) return;

    const valueSelector = valueSelectorFactory(prop);

    const monkeyPatchedProp: PropertyDescriptor = {
      get: getter,
      set: function (this: Entity, value: T) {
        setter.call(this, valueSelector(value));
      },
      configurable: true
    };
    Object.defineProperty(entityProto, prop.name, monkeyPatchedProp);
  };
}
