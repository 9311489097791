import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { TemplateWorkspace } from '../models';
import { templateWorkspaceQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class TemplateWorkspaceRepositoryService extends RepositoryService<TemplateWorkspace> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, templateWorkspaceQueries.selectAll);
  }
}
