import { BaseEntity } from '../base-entity';
import { Role } from './role';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface RoleAccess {
  targetRoleId: string;
  roleId: string;
  tenantKey: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  role: Role;
  targetRole: Role;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class RoleAccess extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
