import { EntityQuery } from 'breeze-client';
import { UserReportFilter } from '../../models';

const baseQuery = EntityQuery.from('UserReportFilters');
const expandExcludedReports = baseQuery.expand(UserReportFilter.propName('excludedWorkspaces'));

export const userReportFilterQueries = {
  selectAll: baseQuery,
  selectExcludedReports: expandExcludedReports
};
