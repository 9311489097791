import { DataProperty } from 'breeze-client';

export interface ValueNormalizerFactory<T> {
  (property: DataProperty): (value?: T) => unknown;
}

const removeEmptyString = (value?: string) => (value === '' ? undefined : value);
const trimString = (value?: string) => value?.trim();
const trimAndRemoveEmptyString = (value?: string) => removeEmptyString(trimString(value));
const normalizeStringFactory: ValueNormalizerFactory<string> = (property: DataProperty) =>
  property.isNullable ? trimAndRemoveEmptyString : trimString;

export const breezeNormalizer = {
  Guid: normalizeStringFactory,
  String: normalizeStringFactory
};
