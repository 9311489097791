import { BaseEntity } from '../base-entity';
import { OrganisationWorkspace } from './organisation-workspace';
import { PbiDeployment } from './pbi-deployment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface OrganisationWorkspaceDeployment {
  id: string;
  createdDate: Date;
  deployStateUpdatedOn: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  organisationWorkspaceId: string;
  pbiDeploymentId: string;
  periodEnd: Date;
  periodStart: Date;
  status: string;
  tenantKey: string;
  organisationWorkspace: OrganisationWorkspace;
  pbiDeployment: PbiDeployment;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class OrganisationWorkspaceDeployment extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
