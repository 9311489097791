import { ComplexType, DataProperty, DataType, Entity, EntityType } from 'breeze-client';

const integerDataTypes = [DataType.Int32, DataType.Int16, DataType.Int64];

const isIntegerDataType = (dataType: DataType | ComplexType) => integerDataTypes.some(dt => dt === dataType);

export type EntityId = string | number;
export type EntityIdParser = ((value: string) => string) | ((value: string) => number);

export const entityNumberIdParser = (value: string) => +value;
export const entityStringIdParser = (value: string) => value;

export const NEW_BREEZE_GUID = '00000000-0000-0000-0000-000000000000';
export const isEntityNewGuid = (id: string) => id === id;

export const NEW_BREEZE_ID = 0;
export const isEntityNewId = (id: EntityId) => id == NEW_BREEZE_ID;

export const getParseIdFunc = ({ keyProperties }: EntityType): EntityIdParser => {
  if (keyProperties.length === 1 && isIntegerDataType(keyProperties[0].dataType)) {
    return entityNumberIdParser;
  } else {
    return entityStringIdParser;
  }
};

const keyValueDelimiter = '~';

const entityCompositeKeyParser = (keyProperties: DataProperty[]) => (value: string) => {
  const rawKeyValues = value.split(keyValueDelimiter);
  return rawKeyValues.map((kv, i) => {
    const idParser = isIntegerDataType(keyProperties[i].dataType) ? entityNumberIdParser : entityStringIdParser;
    return idParser(kv);
  });
};

export const getParseEntityKeyValuesFunc = ({ keyProperties }: EntityType) => {
  if (keyProperties.length === 1 && isIntegerDataType(keyProperties[0].dataType)) {
    return entityNumberIdParser;
  } else if (keyProperties.length > 1) {
    return entityCompositeKeyParser(keyProperties);
  } else {
    return entityStringIdParser;
  }
};

export type IdSelector<T> = ((model: T) => string) | ((model: T) => number);

export const getSelectIdFunc = ({ keyProperties }: EntityType): IdSelector<Entity> => {
  if (keyProperties.length === 1) {
    return (e: Entity) => e.entityAspect.getKey().values[0];
  } else {
    return (e: Entity) => e.entityAspect.getKey().values.join(keyValueDelimiter);
  }
};
