import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ValidationError } from 'breeze-client';
import { isKeyedValidationError } from './keyed-validation-error';

@Pipe({
  name: 'breezeValidationMessage'
})
export class BreezeValidationMessagePipe implements PipeTransform {
  transform(value: ValidationErrors | null): string {
    if (!value) return '';

    return Object.values(value)
      .map(e => (e instanceof ValidationError ? e : isKeyedValidationError(e) ? e.error : e))
      .filter(e => e instanceof ValidationError)
      .map((e: ValidationError) => e.errorMessage)
      .join(';');
  }
}
