import { DefaultEntity } from '@mri/breeze-entity';

export class BaseEntity extends DefaultEntity {
  static serverPlaceholderStringValue = 'REPLACED_ON_SERVER';

  static getPropPath(keys: string[]): string {
    return keys.reduce((acc, key) => `${acc}.${key}`);
  }

  static qualifiedPropName<T>(entityName: string, name: keyof T) {
    return `${entityName}.${name.toString()}`;
  }

  static qualifiedPropNames<T>(entityName: string, ...names: Array<keyof T>) {
    return names.map(name => BaseEntity.qualifiedPropName(entityName, name));
  }
}
