import { Entity, EntityKey, EntityManager, ValidationError } from 'breeze-client';
import xor from 'lodash-es/xor';

export interface KeyedValidationError {
  entityKey: EntityKey;
  error: ValidationError;
}

export const getAllKeyedValidationErrors = (em: EntityManager) => em.getEntities().flatMap(getKeyedValidationErrors);

export const getKeyedValidationErrors = (entity: Entity) =>
  entity.entityAspect.getValidationErrors().map(error => ({ entityKey: entity.entityAspect.getKey(), error }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isKeyedValidationError = (value: any): value is KeyedValidationError => {
  const candidate = value as Partial<KeyedValidationError>;
  return candidate && candidate.entityKey instanceof EntityKey && candidate.error instanceof ValidationError;
};

export const isKeyedValidationErrorsEqual = (left: KeyedValidationError[], right: KeyedValidationError[]) =>
  xor(
    left.map(x => x.error),
    right.map(x => x.error)
  ).length === 0;
