import { IdentityPrincipal } from './identity-principal';
import { GroupMembership } from './group-membership';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { GraphLoadPurpose } from '@mri/breeze-entity';
/// </code-import>

export interface GroupPrincipal {
  members: GroupMembership[];
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class GroupPrincipal extends IdentityPrincipal {
  /// <code> Place custom code between <code> tags
  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.Group),
    [CrudAction.Delete]: [AuthzContext.deleteFor(EntityName.Group), AuthzContext.deleteFor(EntityName.GroupMembership)]
  };
  static propName(name: keyof GroupPrincipal) {
    return name;
  }

  override get childPropertyNames() {
    return [GroupPrincipal.propName('members')];
  }

  override getPropertyPathsFor(purpose: GraphLoadPurpose) {
    if (purpose === GraphLoadPurpose.View) {
      return [`${GroupPrincipal.propName('members')}.${GroupMembership.propName('member')}`];
    } else if (purpose === GraphLoadPurpose.Delete) {
      //Note: We are not loading the members of the group on delete because it is been handled by delete cascade at the DB level on the server side.
      return [];
    } else {
      return this.childPropertyNames;
    }
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
