/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import {
  EntityError,
  EntityKey,
  EntityManager,
  HttpResponse,
  MetadataStore,
  NamingConvention,
  ServerError,
  ValidationError
} from 'breeze-client';
import { EntityErrorFromServer, SaveError } from 'breeze-client/src/entity-manager';
import { PascalCasedProperties } from 'type-fest';
import { getAllKeyedValidationErrors, KeyedValidationError } from './keyed-validation-error';

/**
 * An error object that contains details of entity validation errors that are detected when the save is attempted
 */
export interface ClientSaveError extends Error {
  entityErrors: EntityError[];
}

/**
 * Represent the dto equivalent of a `ClientSaveError` that is safe to serialize as JSON
 */
export interface ClientSaveErrorDto extends Omit<ClientSaveError, 'entityErrors'> {
  entityErrors: EntityErrorDto[];
}

/**
 * An extended version of `HttpResponse` that includes the angular `HttpErrorResponse`
 */
export interface NgHttpResponse extends HttpResponse {
  response: HttpErrorResponse;
}

/**
 * An extended version of `ServerError` that includes the angular `HttpErrorResponse`
 */
export interface NgServerError extends ServerError {
  httpResponse: NgHttpResponse;
}

/**
 * An extended version of `SaveError` that includes the angular `HttpErrorResponse`
 */
export interface NgSaveError extends SaveError {
  httpResponse: NgHttpResponse;
}

/**
 * Represent the dto equivalent of `EntityError` that is safe to serialize as JSON
 */
export interface EntityErrorDto extends Pick<EntityError, 'isServerError'>, EntityErrorFromServer {}

/**
 * Represent the dto equivalent of a `SaveError` that is safe to serialize as JSON
 */
export interface SaveErrorDto extends Omit<NgSaveError, 'entityErrors'> {
  entityErrors: EntityErrorDto[];
}

/**
 * Convert a `EntityError` created by breeze into a dto that is safe to serialize as JSON
 */
function convertEntityErrorToDto(value: EntityError): EntityErrorDto {
  const { entity, ...safeValues } = value;

  // the runtime reality is that `EntityError`.entity` might be assigned
  if (!entity) {
    return { ...safeValues, keyValues: [], entityTypeName: 'Unknown' };
  }

  const key = entity.entityAspect.getKey();
  return {
    ...safeValues,
    keyValues: key.values,
    entityTypeName: key.entityType.name
  };
}

function convertEntityErrorFromServerToDto(e: PascalCasedProperties<EntityErrorFromServer>): EntityErrorDto {
  return {
    errorName: e.ErrorName,
    entityTypeName: MetadataStore.normalizeTypeName(e.EntityTypeName),
    keyValues: e.KeyValues,
    propertyName: e.PropertyName
      ? NamingConvention.defaultInstance.serverPropertyNameToClient(e.PropertyName)
      : e.PropertyName,
    errorMessage: e.ErrorMessage,
    custom: e.Custom,
    isServerError: true
  };
}

/**
 * Convert a `ClientSaveError` created by breeze into a dto that is safe to serialize as JSON
 * <p>
 *   <strong>IMPORTANT</strong>: `err` supplied will be mutated rather than a new error object return.
 *   This is so as to preserve the original call stack of the original JS error object
 * </p>
 */
export function convertClientSaveErrorToDto(err: ClientSaveError): ClientSaveErrorDto {
  err.entityErrors = err.entityErrors.map(convertEntityErrorToDto) as never;
  return err as never as ClientSaveErrorDto;
}

/**
 * Return a list of `ValidationError` that the server has responded with when asked to save changes that are
 * associated with a breeze entity that is NOT available in the current `em` EntityManager instance
 * @param value the server error response
 * @param em the current entity manager
 */
export function getUntrackedEntityValidationErrors(value: NgSaveError, em: EntityManager): KeyedValidationError[] {
  const serverErrs = getServerValidationErrors(value, em);
  if (serverErrs.length === 0) return serverErrs;

  // a server EntityError relating to an entity that's tracked by the EntityManager WILL already be associated with
  // that entity instance. Therefore we fetch these here to find the key to identify these tracked entities so as to
  // exclude their validation errors and to thus leave only those validation errors relating to untracked entities
  const trackedKeys = getAllKeyedValidationErrors(em)
    .filter(e => e.error.isServerError)
    .map(e => e.entityKey);
  return serverErrs.filter(e => !trackedKeys.some(key => key.equals(e.entityKey)));
}

function getServerEntityErrors(serverResponse: HttpErrorResponse) {
  const serverError =
    typeof serverResponse.error === 'string' ? JSON.parse(serverResponse.error) : serverResponse.error;

  // some versions of ASP.NET messes up the JSON serialization of a `ProblemDetails` object and incorrectly returning
  // an `Extensions` dictionary fields rather than flattening these values
  const errorInfo =
    (serverResponse.headers.get('content-type')?.startsWith('application/problem+json;') && serverError.extensions) ??
    serverError;

  return (errorInfo?.EntityErrors ?? []) as PascalCasedProperties<EntityErrorFromServer>[];
}

export function getServerValidationErrors(value: NgSaveError, em: EntityManager): KeyedValidationError[] {
  const entityErrorsFromServer = getServerEntityErrors(value.httpResponse.response);
  const entityErrorsDto = entityErrorsFromServer.map(convertEntityErrorFromServerToDto);
  return entityErrorsDto.map(e => {
    const et = em.metadataStore.getAsEntityType(e.entityTypeName);
    const property = e.propertyName ? et.getProperty(e.propertyName) : undefined;
    const entityKey = new EntityKey(et, e.keyValues);
    const ve = new ValidationError(
      null,
      {
        property,
        propertyName: e.propertyName
      },
      e.errorMessage
    );
    ve.isServerError = true;
    return { entityKey, error: ve };
  });
}

/**
 * Convert a `NgSaveError` created by breeze into a dto that is safe to serialize as JSON
 */
export function convertServerSaveErrorToDto(
  value: NgSaveError,
  entityErrorsFromServer?: PascalCasedProperties<EntityErrorFromServer>[] | undefined
): SaveErrorDto {
  const { entityErrors, httpResponse, ...safeValues } = value;

  // not all server-side `EntityErrorFromServer` instances will map to client-side entity. therefore we're going to
  // prefer to create `EntityErrorDto` instances from `EntityErrorFromServer` rather than `EntityError` instances.
  // doing so means we're going to have an `EntityErrorDto` that has it's expected fields
  const entityErrorsDto = entityErrorsFromServer
    ? entityErrorsFromServer.map(convertEntityErrorFromServerToDto)
    : entityErrors.map(convertEntityErrorToDto);

  return {
    ...safeValues,
    httpResponse: {
      ...httpResponse,
      saveContext: httpResponse.saveContext ? {} : undefined
    },
    entityErrors: entityErrorsDto
  };
}

export function isBreezeClientSaveError(value: any): value is ClientSaveError {
  return isBreezeSaveError(value) && value instanceof Error;
}

function isBreezeHttpResponse(value: any): value is NgHttpResponse {
  return (
    value['config'] != null &&
    typeof value['getHeaders'] === 'function' &&
    value['status'] != null &&
    value['response'] instanceof HttpErrorResponse
  );
}

export function isBreezeSaveError(value: any): value is Pick<SaveError, 'entityErrors'> {
  return Array.isArray(value['entityErrors']);
}

export function isBreezeServerSaveError(value: any): value is NgSaveError {
  return isBreezeSaveError(value) && isBreezeServerError(value);
}

export function isBreezeServerError(value: any): value is NgServerError {
  return (
    value['message'] != null &&
    value['status'] != null &&
    value['httpResponse'] != null &&
    isBreezeHttpResponse(value['httpResponse'])
  );
}
