import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { Dataset } from '../models';
import { datasetQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class DatasetRepositoryService extends RepositoryService<Dataset> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, datasetQueries.selectAll);
  }
}
