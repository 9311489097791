import { Pipe, PipeTransform } from '@angular/core';
import { KeyedValidationError } from './keyed-validation-error';

@Pipe({
  name: 'breezeKeyedValidationFilter'
})
export class BreezeKeyedValidationFilterPipe implements PipeTransform {
  transform(
    value: KeyedValidationError[] | null,
    predicate: (e: KeyedValidationError) => boolean
  ): KeyedValidationError[] {
    if (!value) return [];

    return value.filter(predicate);
  }
}
