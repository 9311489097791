import { IdentityPrincipal } from './identity-principal';
import { ServicePrincipalRole } from './service-principal-role';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface ServicePrincipal {
  canAccessAllTenants: boolean;
  identityProviderClientId: string;
  roles: ServicePrincipalRole[];
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ServicePrincipal extends IdentityPrincipal {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
