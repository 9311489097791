import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { TemplateWorkspaceIcon } from '../models';
import { templateWorkspaceIconQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class TemplateWorkspaceIconRepositoryService extends RepositoryService<TemplateWorkspaceIcon> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, templateWorkspaceIconQueries.selectAll);
  }
}
