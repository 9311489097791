import { MetadataStore } from 'breeze-client';

import { BusinessTransactionLock } from './business-transaction-lock';
import { Bookmark } from './bookmark';
import { ForgottenUser } from './forgotten-user';
import { GroupMembership } from './group-membership';
import { GroupPrincipal } from './group-principal';
import { OrganisationMembership } from './organisation-membership';
import { OrganisationPrincipal } from './organisation-principal';
import { OrganisationWorkspace } from './organisation-workspace';
import { OrganisationWorkspaceDeployment } from './organisation-workspace-deployment';
import { OrganisationWorkspaceInfo } from './organisation-workspace-info';
import { PbiDeployment } from './pbi-deployment';
import { PbiOrganisationWorkspace } from './pbi-organisation-workspace';
import { PbiReport } from './pbi-report';
import { Report } from './report';
import { ReportAccess } from './report-access';
import { ReportActivityRecord } from './report-activity-record';
import { ReportInfo } from './report-info';
import { ReportUserPreference } from './report-user-preference';
import { Role } from './role';
import { RoleAccess } from './role-access';
import { SQLCache } from './sql-cache';
import { ServicePrincipal } from './service-principal';
import { ServicePrincipalRole } from './service-principal-role';
import { TemplateWorkspace } from './template-workspace';
import { TemplateWorkspaceIconImage } from './template-workspace-icon-image';
import { TemplateWorkspaceIconText } from './template-workspace-icon-text';
import { TemplateWorkspaceTargetSettings } from './template-workspace-target-settings';
import { UserPrincipal } from './user-principal';
import { UserReportFilter } from './user-report-filter';
import { UserReportWorkspaceFilter } from './user-report-workspace-filter';
import { UserRole } from './user-role';

export class DsgRegistrationHelper {
  static register(metadataStore: MetadataStore) {
    metadataStore.registerEntityTypeCtor('BusinessTransactionLock', BusinessTransactionLock);
    metadataStore.registerEntityTypeCtor('Bookmark', Bookmark);
    metadataStore.registerEntityTypeCtor('ForgottenUser', ForgottenUser);
    metadataStore.registerEntityTypeCtor('GroupMembership', GroupMembership);
    metadataStore.registerEntityTypeCtor('GroupPrincipal', GroupPrincipal);
    metadataStore.registerEntityTypeCtor('OrganisationMembership', OrganisationMembership);
    metadataStore.registerEntityTypeCtor('OrganisationPrincipal', OrganisationPrincipal);
    metadataStore.registerEntityTypeCtor('OrganisationWorkspace', OrganisationWorkspace);
    metadataStore.registerEntityTypeCtor('OrganisationWorkspaceDeployment', OrganisationWorkspaceDeployment);
    metadataStore.registerEntityTypeCtor('OrganisationWorkspaceInfo', OrganisationWorkspaceInfo);
    metadataStore.registerEntityTypeCtor('PbiDeployment', PbiDeployment);
    metadataStore.registerEntityTypeCtor('PbiOrganisationWorkspace', PbiOrganisationWorkspace);
    metadataStore.registerEntityTypeCtor('PbiReport', PbiReport);
    metadataStore.registerEntityTypeCtor('Report', Report);
    metadataStore.registerEntityTypeCtor('ReportAccess', ReportAccess);
    metadataStore.registerEntityTypeCtor('ReportActivityRecord', ReportActivityRecord);
    metadataStore.registerEntityTypeCtor('ReportInfo', ReportInfo);
    metadataStore.registerEntityTypeCtor('ReportUserPreference', ReportUserPreference);
    metadataStore.registerEntityTypeCtor('Role', Role);
    metadataStore.registerEntityTypeCtor('RoleAccess', RoleAccess);
    metadataStore.registerEntityTypeCtor('SQLCache', SQLCache);
    metadataStore.registerEntityTypeCtor('ServicePrincipal', ServicePrincipal);
    metadataStore.registerEntityTypeCtor('ServicePrincipalRole', ServicePrincipalRole);
    metadataStore.registerEntityTypeCtor('TemplateWorkspace', TemplateWorkspace);
    metadataStore.registerEntityTypeCtor('TemplateWorkspaceIconImage', TemplateWorkspaceIconImage);
    metadataStore.registerEntityTypeCtor('TemplateWorkspaceIconText', TemplateWorkspaceIconText);
    metadataStore.registerEntityTypeCtor('TemplateWorkspaceTargetSettings', TemplateWorkspaceTargetSettings);
    metadataStore.registerEntityTypeCtor('UserPrincipal', UserPrincipal);
    metadataStore.registerEntityTypeCtor('UserReportFilter', UserReportFilter);
    metadataStore.registerEntityTypeCtor('UserReportWorkspaceFilter', UserReportWorkspaceFilter);
    metadataStore.registerEntityTypeCtor('UserRole', UserRole);
  }
}
