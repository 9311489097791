import { IdentityPrincipal } from './identity-principal';
import { Role } from './role';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
/// </code-import>

export interface OrganisationPrincipal {
  defaultRoleId?: string;
  domainName?: string;
  defaultRole: Role;
}

/// <module-code> Place module level code between <module-code> tags
export enum OrganisationPrincipalCustomAction {
  Impersonate = 'Impersonate'
}

export type OrganisationName = Pick<OrganisationPrincipal, 'name'>;
/// </module-code>

export class OrganisationPrincipal extends IdentityPrincipal {
  /// <code> Place custom code between <code> tags
  static authorization = {
    ...AuthzContextsActionMap.crudFor(EntityName.Organisation),
    [OrganisationPrincipalCustomAction.Impersonate]: {
      action: OrganisationPrincipalCustomAction.Impersonate,
      resource: EntityName.Organisation
    }
  };

  static propName(name: keyof OrganisationPrincipal) {
    return name;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
