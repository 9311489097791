import { Injectable, Type } from '@angular/core';
import { BreezePropertyConfig } from './breeze-property-config';
import { FormErrorComponent } from './form-error-base.component';

@Injectable({
  providedIn: 'root'
})
export class BreezeFormErrorTypeService {
  componentType: Type<FormErrorComponent>;

  constructor(propertyConfig: BreezePropertyConfig) {
    this.componentType = propertyConfig.formErrorComponent;
  }

  registerType(componentType: Type<FormErrorComponent>) {
    this.componentType = componentType;
  }
}
