import { Directive, Input } from '@angular/core';
import { EntityType, MetadataStore } from 'breeze-client';
import { ReplaySubject } from 'rxjs';

@Directive({
  selector: '[breezeEntityTypeSource]'
})
export class BreezeEntityTypeSourceDirective {
  private entityTypeSub = new ReplaySubject<EntityType>(1);
  entityType$ = this.entityTypeSub.asObservable();

  @Input() set breezeEntityTypeSource(value: EntityType | string) {
    if (typeof value === 'string') {
      this.entityTypeSub.next(this.metadataStore.getAsEntityType(value, false));
    } else {
      this.entityTypeSub.next(value);
    }
  }
  constructor(private metadataStore: MetadataStore) {}
}
