import { Entity, EntityQuery } from 'breeze-client';

export type EntityInstanceQuery<T extends Entity = Entity, U extends Entity = Entity> = (entity: T) => Promise<U[]>;

/**
 * Return a function that will ensure the navigation property supplied is loaded for an entity and return the
 * array containing the associated entities for that navigation property
 * */
export function loadNavigationProperty<T extends Entity = Entity, C extends Entity = Entity>(
  navigationPropertyName: keyof T
): EntityInstanceQuery<T, C> {
  return async (entity: Entity): Promise<C[]> => {
    const propertyName = navigationPropertyName as string;
    if (!entity.entityAspect.isNavigationPropertyLoaded(propertyName)) {
      await entity.entityAspect.loadNavigationProperty(propertyName);
    }
    const association = entity.entityAspect.getPropertyValue(propertyName);
    return Array.isArray(association) ? association : [association];
  };
}

/**
 * Return a function that will run all the supplied `queries` in parallel and return a flat array containing all the
 * entity instances returned by those queries
 * */
export function composeEntityInstanceQueries<T extends Entity = Entity>(
  queries: EntityInstanceQuery<T>[]
): EntityInstanceQuery<T> {
  return async (entity: T) => {
    const results = await Promise.all(queries.map(q => q(entity)));
    return results.flat();
  };
}

export interface GraphQueryResult<T extends Entity> {
  entity: T;
  associations: Entity[];
}

export type EntityGraphQuery<T extends Entity = Entity> = (entity: T) => Promise<GraphQueryResult<T> | undefined>;

export enum GraphLoadPurpose {
  View,
  Delete,
  Edit
}

/**
 * Return a function that will load an entity along with it's graph of associated entities as defined by the `paths`
 * supplied
 * */
export function loadGraphQuery<T extends Entity>(paths: string[]): EntityGraphQuery<T> {
  return async (entity: Entity): Promise<GraphQueryResult<T> | undefined> => {
    const entityQuery = EntityQuery.fromEntities([entity]).expand(paths);
    const { results, retrievedEntities } = await entityQuery.execute();
    if (results.length === 0) {
      return undefined;
    }
    return {
      entity: entity as T,
      associations: (retrievedEntities ?? []).filter(x => x !== entity)
    };
  };
}

export interface GraphsQueryResult<T extends Entity> {
  roots: T[];
  associations: Entity[];
}

export type EntityGraphsQuery<T extends Entity = Entity> = (list: T[]) => Promise<GraphsQueryResult<T>>;

/**
 * Return a function that will load an entity along with it's graph of associated entities as defined by the `paths`
 * supplied
 * */
export function loadGraphsQuery<T extends Entity>(paths: string[]): EntityGraphsQuery<T> {
  return async (list: T[]): Promise<GraphsQueryResult<T>> => {
    const entityQuery = EntityQuery.fromEntities(list).expand(paths);
    const { results, retrievedEntities } = await entityQuery.execute();
    return {
      roots: results as T[],
      associations: (retrievedEntities ?? []).filter(x => results.find(e => e === x) == null)
    };
  };
}
