import { Attribute, Directive, Input, Optional } from '@angular/core';

@Directive({
  selector: '[breezeProperty]'
})
export class BreezePropertyDirective {
  @Input() set breezeProperty(value: string) {
    if (value) {
      this.name = value;
    }
  }

  constructor(@Optional() @Attribute('name') public name: string) {}
}
