import { BaseEntity } from '../base-entity';
import { IdentityPrincipal } from './identity-principal';
import { Report } from './report';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction, CrudAllAction } from '@mri-platform/resource-authz';
/// </code-import>

export interface ReportAccess {
  targetId: string;
  identityPrincipalId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  identityPrincipal: IdentityPrincipal;
  target: Report;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ReportAccess extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.ReportAccess),
    [CrudAllAction.Read]: [AuthzContext.readFor(EntityName.Report), AuthzContext.readAllFor(EntityName.ReportAccess)],
    [CrudAction.Update]: [
      AuthzContext.createFor(EntityName.ReportAccess),
      AuthzContext.deleteFor(EntityName.ReportAccess)
    ]
  };

  static propName(name: keyof ReportAccess) {
    return name;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
