import { Validator } from 'breeze-client';
import { ValidationFn, ValidationMessageContext } from 'breeze-client/src/validate';

const validatorName = 'uniqueValue';
const defaultMessageContext: ValidationMessageContext = {
  messageTemplate: "'%displayName%' must be unique"
};
export function uniqueValueValidator(predicate: ValidationFn, context?: ValidationMessageContext) {
  const ctx: ValidationMessageContext = {
    ...defaultMessageContext,
    ...context
  };
  return new Validator(validatorName, predicate, ctx);
}

export interface uniqueValueValidator {
  validatorName: string;
}

uniqueValueValidator.validatorName = validatorName;
