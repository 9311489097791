export class DsgMetadata {
  public static value = {
    structuralTypes: [
      {
        shortName: 'BusinessTransactionLock',
        namespace: 'DataServicesGateway.Shared.Data',
        autoGeneratedKeyType: 'None',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'String',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Key',
            validators: [{ name: 'required' }]
          },
          { dataType: 'DateTime', isNullable: true, nameOnServer: 'Expiry', validators: [{ name: 'date' }] }
        ],
        navigationProperties: []
      },
      {
        shortName: 'Bookmark',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'Bookmarks',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDefault',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'ReportId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'String', isNullable: false, nameOnServer: 'State', validators: [{ name: 'required' }] },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName: 'DataServicesGateway.Shared.Model.Bookmark_DataServicesGateway.Shared.Model.Report_Report',
            foreignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'Report',
            validators: []
          }
        ]
      },
      {
        shortName: 'ForgottenUser',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'ForgottenUsers',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          }
        ],
        navigationProperties: []
      },
      {
        shortName: 'GroupMembership',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'GroupMemberships',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'MemberId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'GroupId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'GroupPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.GroupMembership_DataServicesGateway.Shared.Model.GroupPrincipal_Group',
            foreignKeyNamesOnServer: ['GroupId'],
            nameOnServer: 'Group',
            validators: []
          },
          {
            entityTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.GroupMembership_DataServicesGateway.Shared.Model.IdentityPrincipal_Member',
            foreignKeyNamesOnServer: ['MemberId'],
            nameOnServer: 'Member',
            validators: []
          }
        ]
      },
      {
        shortName: 'GroupPrincipal',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'Groups',
        isComplexType: false,
        dataProperties: [],
        navigationProperties: [
          {
            entityTypeName: 'GroupMembership:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.GroupMembership_DataServicesGateway.Shared.Model.GroupPrincipal_Group',
            invForeignKeyNamesOnServer: ['GroupId'],
            nameOnServer: 'Members',
            validators: []
          }
        ]
      },
      {
        shortName: 'IdentityPrincipal',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        isAbstract: true,
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 100,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 100, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 30,
            nameOnServer: 'Type',
            validators: [{ name: 'required' }, { maxLength: 30, name: 'maxLength' }]
          }
        ],
        navigationProperties: []
      },
      {
        shortName: 'OrganisationMembership',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'OrganisationMemberships',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'OrganisationId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationMembership_DataServicesGateway.Shared.Model.OrganisationPrincipal_Organisation',
            foreignKeyNamesOnServer: ['OrganisationId'],
            nameOnServer: 'Organisation',
            validators: []
          },
          {
            entityTypeName: 'UserPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationMembership_DataServicesGateway.Shared.Model.UserPrincipal_User',
            foreignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'User',
            validators: []
          }
        ]
      },
      {
        shortName: 'OrganisationPrincipal',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'Organisations',
        isComplexType: false,
        dataProperties: [
          { dataType: 'Guid', isNullable: true, nameOnServer: 'DefaultRoleId', validators: [{ name: 'guid' }] },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 253,
            nameOnServer: 'DomainName',
            validators: [{ maxLength: 253, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Role:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationPrincipal_DataServicesGateway.Shared.Model.Role_DefaultRole',
            foreignKeyNamesOnServer: ['DefaultRoleId'],
            nameOnServer: 'DefaultRole',
            validators: []
          }
        ]
      },
      {
        shortName: 'OrganisationWorkspace',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'Workspaces',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'CapacityId', validators: [{ name: 'guid' }] },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'DeployStateUpdatedOn',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'EnforceAnyStandardRlsRoles',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 1000,
            nameOnServer: 'ModuleCodes',
            validators: [{ maxLength: 1000, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OwnerId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 10,
            nameOnServer: 'Region',
            validators: [{ maxLength: 10, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'ServicePrincipalProfile',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'TemplateWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'WorkspaceType',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspace_DataServicesGateway.Shared.Model.OrganisationPrincipal_Owner',
            foreignKeyNamesOnServer: ['OwnerId'],
            nameOnServer: 'Owner',
            validators: []
          },
          {
            entityTypeName: 'PbiOrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.PbiOrganisationWorkspace_DataServicesGateway.Shared.Model.OrganisationWorkspace_OrganisationWorkspace',
            invForeignKeyNamesOnServer: ['OrganisationWorkspaceId'],
            nameOnServer: 'PbiWorkspaces',
            validators: []
          },
          {
            entityTypeName: 'TemplateWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspace_DataServicesGateway.Shared.Model.TemplateWorkspace_TemplateWorkspace',
            foreignKeyNamesOnServer: ['TemplateWorkspaceId'],
            nameOnServer: 'TemplateWorkspace',
            validators: []
          }
        ]
      },
      {
        shortName: 'OrganisationWorkspaceDeployment',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'WorkspaceDeployments',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'CreatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'DeployStateUpdatedOn',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OrganisationWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'PbiDeploymentId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DataServicesGateway.Shared.Model.OrganisationWorkspaceDeploymentStatus',
            enumType: 'OrganisationWorkspaceDeploymentStatus:#DataServicesGateway.Shared.Model',
            isNullable: false,
            maxLength: 50,
            nameOnServer: 'Status',
            validators: [{ name: 'required' }, { maxLength: 50, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspaceDeployment_DataServicesGateway.Shared.Model.OrganisationWorkspace_OrganisationWorkspace',
            foreignKeyNamesOnServer: ['OrganisationWorkspaceId'],
            nameOnServer: 'OrganisationWorkspace',
            validators: []
          },
          {
            entityTypeName: 'PbiDeployment:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspaceDeployment_DataServicesGateway.Shared.Model.PbiDeployment_PbiDeployment',
            foreignKeyNamesOnServer: ['PbiDeploymentId'],
            nameOnServer: 'PbiDeployment',
            validators: []
          }
        ]
      },
      {
        shortName: 'OrganisationWorkspaceInfo',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'WorkspaceInfos',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'TemplateWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'WorkspaceType',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'TemplateWorkspaceIcon:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspaceInfo_DataServicesGateway.Shared.Model.TemplateWorkspaceIcon_TemplateWorkspaceIcon',
            foreignKeyNamesOnServer: ['TemplateWorkspaceId'],
            nameOnServer: 'TemplateWorkspaceIcon',
            validators: []
          }
        ]
      },
      {
        shortName: 'PbiDeployment',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'PbiDeployments',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'CreatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 200,
            nameOnServer: 'OrchestratorInstanceId',
            validators: [{ maxLength: 200, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'DataServicesGateway.Shared.Model.PbiDeploymentTrigger',
            enumType: 'PbiDeploymentTrigger:#DataServicesGateway.Shared.Model',
            isNullable: false,
            maxLength: 50,
            nameOnServer: 'TriggerType',
            validators: [{ name: 'required' }, { maxLength: 50, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationWorkspaceDeployment:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationWorkspaceDeployment_DataServicesGateway.Shared.Model.PbiDeployment_PbiDeployment',
            invForeignKeyNamesOnServer: ['PbiDeploymentId'],
            nameOnServer: 'WorkspaceDeployments',
            validators: []
          }
        ]
      },
      {
        shortName: 'PbiOrganisationWorkspace',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'PbiWorkspaces',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'CreatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OrganisationWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'PbiWorkspaceType',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Status',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.PbiOrganisationWorkspace_DataServicesGateway.Shared.Model.OrganisationWorkspace_OrganisationWorkspace',
            foreignKeyNamesOnServer: ['OrganisationWorkspaceId'],
            nameOnServer: 'OrganisationWorkspace',
            validators: []
          },
          {
            entityTypeName: 'PbiReport:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.PbiReport_DataServicesGateway.Shared.Model.PbiOrganisationWorkspace_PbiWorkspace',
            invForeignKeyNamesOnServer: ['PbiWorkspaceId'],
            nameOnServer: 'PbiReports',
            validators: []
          }
        ]
      },
      {
        shortName: 'PbiReport',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'PbiReports',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'CreatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            concurrencyMode: 'Fixed',
            nameOnServer: 'DatasetId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 500,
            nameOnServer: 'Description',
            validators: [{ maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'EmbedUrl',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDraft',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'OwnerId', validators: [{ name: 'guid' }] },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'PbiWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'ReportId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'WebUrl',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.PbiReport_DataServicesGateway.Shared.Model.IdentityPrincipal_Owner',
            foreignKeyNamesOnServer: ['OwnerId'],
            nameOnServer: 'Owner',
            validators: []
          },
          {
            entityTypeName: 'PbiOrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.PbiReport_DataServicesGateway.Shared.Model.PbiOrganisationWorkspace_PbiWorkspace',
            foreignKeyNamesOnServer: ['PbiWorkspaceId'],
            nameOnServer: 'PbiWorkspace',
            validators: []
          },
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.PbiReport_DataServicesGateway.Shared.Model.Report_Report',
            foreignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'Report',
            validators: []
          }
        ]
      },
      {
        shortName: 'Report',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'Reports',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'CreatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 500,
            nameOnServer: 'Description',
            validators: [{ maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'EmbedUrl',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'HasStandardRlsRoles',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'HidesReportId', validators: [{ name: 'guid' }] },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsCustom',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDefault',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDeleted',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDetached',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            concurrencyMode: 'Fixed',
            nameOnServer: 'IsPublished',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 4000,
            nameOnServer: 'LatestProblem',
            validators: [{ maxLength: 4000, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OrganisationWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OwnerId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'ParentReportId', validators: [{ name: 'guid' }] },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 500,
            nameOnServer: 'TemplateDatasetName',
            validators: [{ maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 500,
            nameOnServer: 'TemplateReportName',
            validators: [{ maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'Int32',
            isNullable: false,
            nameOnServer: 'Version',
            validators: [{ name: 'required' }, { name: 'int32' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'WebUrl',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'ReportActivityRecord:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.ReportActivityRecord_DataServicesGateway.Shared.Model.Report_Report',
            invForeignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'ActivityRecords',
            validators: []
          },
          {
            entityTypeName: 'ReportAccess:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.ReportAccess_DataServicesGateway.Shared.Model.Report_Target',
            invForeignKeyNamesOnServer: ['TargetId'],
            nameOnServer: 'AllowedPrincipals',
            validators: []
          },
          {
            entityTypeName: 'Bookmark:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName: 'DataServicesGateway.Shared.Model.Bookmark_DataServicesGateway.Shared.Model.Report_Report',
            invForeignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'Bookmarks',
            validators: []
          },
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.Report_DataServicesGateway.Shared.Model.Report_HidesReport',
            foreignKeyNamesOnServer: ['HidesReportId'],
            nameOnServer: 'HidesReport',
            validators: []
          },
          {
            entityTypeName: 'OrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.Report_DataServicesGateway.Shared.Model.OrganisationWorkspace_OrganisationWorkspace',
            foreignKeyNamesOnServer: ['OrganisationWorkspaceId'],
            nameOnServer: 'OrganisationWorkspace',
            validators: []
          },
          {
            entityTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.Report_DataServicesGateway.Shared.Model.IdentityPrincipal_Owner',
            foreignKeyNamesOnServer: ['OwnerId'],
            nameOnServer: 'Owner',
            validators: []
          },
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.Report_DataServicesGateway.Shared.Model.Report_ParentReport',
            foreignKeyNamesOnServer: ['ParentReportId'],
            nameOnServer: 'ParentReport',
            validators: []
          },
          {
            entityTypeName: 'PbiReport:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.PbiReport_DataServicesGateway.Shared.Model.Report_Report',
            invForeignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'PbiReports',
            validators: []
          },
          {
            entityTypeName: 'ReportUserPreference:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.ReportUserPreference_DataServicesGateway.Shared.Model.Report_Report',
            invForeignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'UserPreferences',
            validators: []
          }
        ]
      },
      {
        shortName: 'ReportAccess',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'ReportAccesses',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'TargetId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'IdentityPrincipalId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportAccess_DataServicesGateway.Shared.Model.IdentityPrincipal_IdentityPrincipal',
            foreignKeyNamesOnServer: ['IdentityPrincipalId'],
            nameOnServer: 'IdentityPrincipal',
            validators: []
          },
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportAccess_DataServicesGateway.Shared.Model.Report_Target',
            foreignKeyNamesOnServer: ['TargetId'],
            nameOnServer: 'Target',
            validators: []
          }
        ]
      },
      {
        shortName: 'ReportActivityRecord',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'ReportActivityRecords',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'ActivityDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DataServicesGateway.Shared.Model.ReportActivityType',
            enumType: 'ReportActivityType:#DataServicesGateway.Shared.Model',
            isNullable: false,
            nameOnServer: 'ActivityType',
            validators: [{ name: 'required' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'ReportId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportActivityRecord_DataServicesGateway.Shared.Model.Report_Report',
            foreignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'Report',
            validators: []
          },
          {
            entityTypeName: 'UserPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportActivityRecord_DataServicesGateway.Shared.Model.UserPrincipal_User',
            foreignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'User',
            validators: []
          }
        ]
      },
      {
        shortName: 'ReportInfo',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'DatasetId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'String', isNullable: true, nameOnServer: 'Description', validators: [] },
          { dataType: 'String', isNullable: false, nameOnServer: 'EmbedUrl', validators: [{ name: 'required' }] },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'HiddenById', validators: [{ name: 'guid' }] },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'HidesReportId', validators: [{ name: 'guid' }] },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsCustom',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDefault',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsFavorite',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsPublished',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          { dataType: 'DateTime', isNullable: true, nameOnServer: 'LastViewed', validators: [{ name: 'date' }] },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 4000,
            nameOnServer: 'LatestProblem',
            validators: [{ maxLength: 4000, name: 'maxLength' }]
          },
          { dataType: 'String', isNullable: false, nameOnServer: 'Name', validators: [{ name: 'required' }] },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OrganisationWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'OwnerId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'Guid', isNullable: true, nameOnServer: 'ParentReportId', validators: [{ name: 'guid' }] },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'PbiReportId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'PbiWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          { dataType: 'String', isNullable: true, nameOnServer: 'TemplateDatasetName', validators: [] },
          { dataType: 'String', isNullable: true, nameOnServer: 'TemplateReportName', validators: [] },
          {
            dataType: 'Int32',
            isNullable: false,
            nameOnServer: 'Version',
            validators: [{ name: 'required' }, { name: 'int32' }]
          },
          { dataType: 'String', isNullable: false, nameOnServer: 'WebUrl', validators: [{ name: 'required' }] }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationWorkspaceInfo:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportInfo_DataServicesGateway.Shared.Model.OrganisationWorkspaceInfo_OrganisationWorkspace',
            foreignKeyNamesOnServer: ['OrganisationWorkspaceId'],
            nameOnServer: 'OrganisationWorkspace',
            validators: []
          }
        ]
      },
      {
        shortName: 'ReportUserPreference',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'ReportUserPreferences',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'ReportId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsDefault',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsFavorite',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Report:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportUserPreference_DataServicesGateway.Shared.Model.Report_Report',
            foreignKeyNamesOnServer: ['ReportId'],
            nameOnServer: 'Report',
            validators: []
          },
          {
            entityTypeName: 'UserPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ReportUserPreference_DataServicesGateway.Shared.Model.UserPrincipal_User',
            foreignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'User',
            validators: []
          }
        ]
      },
      {
        shortName: 'Role',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'Roles',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Description',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsActive',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsBuiltIn',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsGlobal',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsServicePrincipalOnly',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 100,
            nameOnServer: 'SecurityGroupId',
            validators: [{ maxLength: 100, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: true,
            maxLength: 50,
            nameOnServer: 'SecurityGroupType',
            validators: [{ maxLength: 50, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: []
      },
      {
        shortName: 'RoleAccess',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'RoleAccesses',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'TargetRoleId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'RoleId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isPartOfKey: true,
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Role:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName: 'DataServicesGateway.Shared.Model.RoleAccess_DataServicesGateway.Shared.Model.Role_Role',
            foreignKeyNamesOnServer: ['RoleId'],
            nameOnServer: 'Role',
            validators: []
          },
          {
            entityTypeName: 'Role:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.RoleAccess_DataServicesGateway.Shared.Model.Role_TargetRole',
            foreignKeyNamesOnServer: ['TargetRoleId'],
            nameOnServer: 'TargetRole',
            validators: []
          }
        ]
      },
      {
        shortName: 'SQLCache',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'String',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }]
          },
          {
            dataType: 'DateTimeOffset',
            isNullable: true,
            nameOnServer: 'AbsoluteExpiration',
            validators: [{ name: 'date' }]
          },
          {
            dataType: 'DateTimeOffset',
            isNullable: false,
            nameOnServer: 'ExpiresAtTime',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'Int64',
            isNullable: true,
            nameOnServer: 'SlidingExpirationInSeconds',
            validators: [{ name: 'int64' }]
          },
          { dataType: 'Binary', isNullable: false, nameOnServer: 'Value', validators: [{ name: 'required' }] }
        ],
        navigationProperties: []
      },
      {
        shortName: 'ServicePrincipal',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'ServicePrincipals',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'CanAccessAllTenants',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'IdentityProviderClientId',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'ServicePrincipalRole:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.ServicePrincipalRole_DataServicesGateway.Shared.Model.ServicePrincipal_ServicePrincipal',
            invForeignKeyNamesOnServer: ['ServicePrincipalId'],
            nameOnServer: 'Roles',
            validators: []
          }
        ]
      },
      {
        shortName: 'ServicePrincipalRole',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'RoleId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'ServicePrincipalId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Role:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ServicePrincipalRole_DataServicesGateway.Shared.Model.Role_Role',
            foreignKeyNamesOnServer: ['RoleId'],
            nameOnServer: 'Role',
            validators: []
          },
          {
            entityTypeName: 'ServicePrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.ServicePrincipalRole_DataServicesGateway.Shared.Model.ServicePrincipal_ServicePrincipal',
            foreignKeyNamesOnServer: ['ServicePrincipalId'],
            nameOnServer: 'ServicePrincipal',
            validators: []
          }
        ]
      },
      {
        shortName: 'TemplateWorkspace',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'TemplateWorkspaces',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: true,
            nameOnServer: 'AuthorizedServicePrincipalId',
            validators: [{ name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Name',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'ProductNameOrFamily',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'TemplatePbiWorkspaceName',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'ServicePrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.TemplateWorkspace_DataServicesGateway.Shared.Model.ServicePrincipal_AuthorizedServicePrincipal',
            foreignKeyNamesOnServer: ['AuthorizedServicePrincipalId'],
            nameOnServer: 'AuthorizedServicePrincipal',
            validators: []
          },
          {
            entityTypeName: 'TemplateWorkspaceIcon:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.TemplateWorkspaceIcon_DataServicesGateway.Shared.Model.TemplateWorkspace_TemplateWorkspace',
            invForeignKeyNamesOnServer: ['Id'],
            nameOnServer: 'Icon',
            validators: []
          },
          {
            entityTypeName: 'TemplateWorkspaceTargetSettings:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.TemplateWorkspaceTargetSettings_DataServicesGateway.Shared.Model.TemplateWorkspace_TemplateWorkspace',
            invForeignKeyNamesOnServer: ['TemplateWorkspaceId'],
            nameOnServer: 'TargetSettings',
            validators: []
          }
        ]
      },
      {
        shortName: 'TemplateWorkspaceIcon',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'TemplateWorkspaceIcons',
        isAbstract: true,
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 7,
            nameOnServer: 'BackgroundColor',
            validators: [{ name: 'required' }, { maxLength: 7, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Label',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 9,
            nameOnServer: 'Type',
            validators: [{ name: 'required' }, { maxLength: 9, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'TemplateWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.TemplateWorkspaceIcon_DataServicesGateway.Shared.Model.TemplateWorkspace_TemplateWorkspace',
            foreignKeyNamesOnServer: ['Id'],
            nameOnServer: 'TemplateWorkspace',
            validators: []
          }
        ]
      },
      {
        shortName: 'TemplateWorkspaceIconImage',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'TemplateWorkspaceIcon:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'TemplateWorkspaceIconImages',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 256,
            nameOnServer: 'FileName',
            validators: [{ name: 'required' }, { maxLength: 256, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 256,
            nameOnServer: 'Path',
            validators: [{ name: 'required' }, { maxLength: 256, name: 'maxLength' }]
          },
          {
            dataType: 'Uri',
            isNullable: false,
            maxLength: 1024,
            nameOnServer: 'Source',
            validators: [{ name: 'required' }, { maxLength: 1024, name: 'maxLength' }]
          }
        ],
        navigationProperties: []
      },
      {
        shortName: 'TemplateWorkspaceIconText',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'TemplateWorkspaceIcon:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'TemplateWorkspaceIconTexts',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 3,
            nameOnServer: 'Initials',
            validators: [{ name: 'required' }, { maxLength: 3, name: 'maxLength' }]
          }
        ],
        navigationProperties: []
      },
      {
        shortName: 'TemplateWorkspaceTargetSettings',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'TemplateWorkspaceTargetSettings',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'TemplateWorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isPartOfKey: true,
            isNullable: false,
            maxLength: 10,
            nameOnServer: 'Region',
            validators: [{ name: 'required' }, { maxLength: 10, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'CapacityId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'GatewayId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'IsRegionNeutralFallback',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'TemplateWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.TemplateWorkspaceTargetSettings_DataServicesGateway.Shared.Model.TemplateWorkspace_TemplateWorkspace',
            foreignKeyNamesOnServer: ['TemplateWorkspaceId'],
            nameOnServer: 'TemplateWorkspace',
            validators: []
          }
        ]
      },
      {
        shortName: 'UserPrincipal',
        namespace: 'DataServicesGateway.Shared.Model',
        baseTypeName: 'IdentityPrincipal:#DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'Users',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'Email',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'FirstName',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'IdentityProviderUserId',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 500,
            nameOnServer: 'LastName',
            validators: [{ name: 'required' }, { maxLength: 500, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'OrganisationMembership:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.OrganisationMembership_DataServicesGateway.Shared.Model.UserPrincipal_User',
            invForeignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'Organisations',
            validators: []
          },
          {
            entityTypeName: 'UserRole:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.UserRole_DataServicesGateway.Shared.Model.UserPrincipal_User',
            invForeignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'UserRoles',
            validators: []
          }
        ]
      },
      {
        shortName: 'UserReportFilter',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'Identity',
        defaultResourceName: 'UserReportFilters',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'Id',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'ExcludeUnassigned',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'Boolean',
            isNullable: false,
            nameOnServer: 'ExcludeUnpublished',
            validators: [{ name: 'required' }, { name: 'bool' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'Guid',
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'UserReportWorkspaceFilter:#DataServicesGateway.Shared.Model',
            isScalar: false,
            associationName:
              'DataServicesGateway.Shared.Model.UserReportWorkspaceFilter_DataServicesGateway.Shared.Model.UserReportFilter_UserReportFilter',
            invForeignKeyNamesOnServer: ['UserId', 'TenantKey'],
            nameOnServer: 'ExcludedWorkspaces',
            validators: []
          },
          {
            entityTypeName: 'OrganisationMembership:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.UserReportFilter_DataServicesGateway.Shared.Model.OrganisationMembership_OrganisationMembership',
            foreignKeyNamesOnServer: ['UserId', 'TenantKey'],
            invForeignKeyNamesOnServer: ['UserId', 'TenantKey'],
            nameOnServer: 'OrganisationMembership',
            validators: []
          }
        ]
      },
      {
        shortName: 'UserReportWorkspaceFilter',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'UserReportWorkspaceFilters',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'WorkspaceId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'UserReportFilter:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.UserReportWorkspaceFilter_DataServicesGateway.Shared.Model.UserReportFilter_UserReportFilter',
            foreignKeyNamesOnServer: ['UserId', 'TenantKey'],
            invForeignKeyNamesOnServer: ['UserId', 'TenantKey'],
            nameOnServer: 'UserReportFilter',
            validators: []
          },
          {
            entityTypeName: 'OrganisationWorkspace:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.UserReportWorkspaceFilter_DataServicesGateway.Shared.Model.OrganisationWorkspace_Workspace',
            foreignKeyNamesOnServer: ['WorkspaceId'],
            nameOnServer: 'Workspace',
            validators: []
          }
        ]
      },
      {
        shortName: 'UserRole',
        namespace: 'DataServicesGateway.Shared.Model',
        autoGeneratedKeyType: 'None',
        defaultResourceName: 'UserRoles',
        isComplexType: false,
        dataProperties: [
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'RoleId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'Guid',
            isPartOfKey: true,
            isNullable: false,
            nameOnServer: 'UserId',
            validators: [{ name: 'required' }, { name: 'guid' }]
          },
          {
            dataType: 'String',
            isPartOfKey: true,
            isNullable: false,
            maxLength: 36,
            concurrencyMode: 'Fixed',
            nameOnServer: 'TenantKey',
            validators: [{ name: 'required' }, { maxLength: 36, name: 'maxLength' }]
          },
          {
            dataType: 'String',
            isNullable: false,
            maxLength: 150,
            nameOnServer: 'LastUpdatedBy',
            validators: [{ name: 'required' }, { maxLength: 150, name: 'maxLength' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'LastUpdatedDate',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodEnd',
            validators: [{ name: 'required' }, { name: 'date' }]
          },
          {
            dataType: 'DateTime',
            isNullable: false,
            nameOnServer: 'PeriodStart',
            validators: [{ name: 'required' }, { name: 'date' }]
          }
        ],
        navigationProperties: [
          {
            entityTypeName: 'Role:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName: 'DataServicesGateway.Shared.Model.UserRole_DataServicesGateway.Shared.Model.Role_Role',
            foreignKeyNamesOnServer: ['RoleId'],
            nameOnServer: 'Role',
            validators: []
          },
          {
            entityTypeName: 'UserPrincipal:#DataServicesGateway.Shared.Model',
            isScalar: true,
            associationName:
              'DataServicesGateway.Shared.Model.UserRole_DataServicesGateway.Shared.Model.UserPrincipal_User',
            foreignKeyNamesOnServer: ['UserId'],
            nameOnServer: 'User',
            validators: []
          }
        ]
      }
    ],
    enumTypes: [
      {
        shortName: 'OrganisationWorkspaceDeploymentStatus',
        namespace: 'DataServicesGateway.Shared.Model',
        values: ['Pending', 'InProgress', 'Completed', 'Failed', 'Cancelled'],
        ordinals: [0, 1, 2, 3, 4]
      },
      {
        shortName: 'PbiDeploymentTrigger',
        namespace: 'DataServicesGateway.Shared.Model',
        values: ['Default', 'ClientUpdate', 'SourceControl'],
        ordinals: [0, 1, 2]
      },
      {
        shortName: 'ReportActivityType',
        namespace: 'DataServicesGateway.Shared.Model',
        values: ['View', 'SaveDraft', 'ImportDraft', 'Publish'],
        ordinals: [0, 1, 2, 3]
      }
    ]
  };
}
