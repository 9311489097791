import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BREEZE_ENTITY_REGISTRATION_FUNC, BREEZE_METADATA_TOKEN } from '@mri/breeze-entity';
import {
  Dataset,
  DsgMetadata,
  IdentityPrincipal,
  OrganisationWorkspace,
  Report,
  ReportInfo,
  TemplateWorkspace,
  TemplateWorkspaceIcon,
  UserPrincipal,
  UserRole
} from './models';
import { registerBreezeLibraryFixes } from './models/register-breeze-library-fixes';
import { registerModelConventions } from './models/register-model-conventions';
import { DsgRegistrationHelper } from './models/registration-helper';
import { registerSharedValidators } from './models/shared-validators';
import { testingModules } from './testing';

@NgModule({
  imports: [CommonModule, testingModules],
  providers: [
    { provide: BREEZE_METADATA_TOKEN, useValue: DsgMetadata.value },
    // important: make sure registerBreezeLibraryFixes registered very early
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: registerBreezeLibraryFixes, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: registerSharedValidators, multi: true },
    // important: make sure registerModelConventions occurs before entity specific registration functions
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: DsgRegistrationHelper.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: registerModelConventions, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: UserPrincipal.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: IdentityPrincipal.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: ReportInfo.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: Dataset.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: UserRole.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: Report.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: TemplateWorkspace.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: TemplateWorkspaceIcon.register, multi: true },
    { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: OrganisationWorkspace.register, multi: true }
  ]
})
export class EntityStateModule {}

export { BaseEntity } from './base-entity';
