import { BaseEntity } from '../base-entity';
import { TemplateWorkspace } from './template-workspace';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { MetadataStore } from 'breeze-client';
/// </code-import>

export interface TemplateWorkspaceIcon {
  id: string;
  backgroundColor: string;
  label: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  type: string;
  templateWorkspace: TemplateWorkspace;
}

/// <module-code> Place module level code between <module-code> tags
export enum IconType {
  IconImage = 'IconImage',
  IconText = 'IconText'
}
/// </module-code>

export abstract class TemplateWorkspaceIcon extends BaseEntity {
  /// <code> Place custom code between <code> tags
  private static typeNameSuffix = EntityName.TemplateWorkspace;

  static basePropName(name: keyof TemplateWorkspaceIcon) {
    return name;
  }

  static register(metadataStore: MetadataStore) {
    metadataStore.setEntityTypeForResourceName('TemplateWorkspaceIcons', EntityName.TemplateWorkspaceIcon);

    const et = metadataStore.getAsEntityType(EntityName.TemplateWorkspaceIcon, false);

    et.subtypes
      .flatMap(st => st.dataProperties.filter(p => p.name === TemplateWorkspaceIcon.basePropName('type')))
      .forEach(p => {
        p.defaultValue = p.parentType.shortName.replace(TemplateWorkspaceIcon.typeNameSuffix, '');
      });
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
