import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { OrganisationWorkspaceInfo } from '../models';
import { organisationWorkspaceInfoQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class OrganisationWorkspaceInfoRepositoryService extends RepositoryService<OrganisationWorkspaceInfo> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, organisationWorkspaceInfoQueries.selectAll);
  }
}
