import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { ReportAccess } from '../models';
import { reportAccessQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class ReportAccessRepositoryService extends RepositoryService<ReportAccess> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, reportAccessQueries.selectAll);
  }
}
