import { Directive, ElementRef, OnDestroy, Optional, Renderer2 } from '@angular/core';
import { combineLatest, EMPTY, merge, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { BreezeEntityTypeSourceDirective } from './breeze-entity-type-source.directive';
import { BreezeModelSourceDirective } from './breeze-model-source.directive';
import { BreezePropertyConfig, optionsFrom } from './breeze-property-config';
import { BreezePropertyOptionsDirective } from './breeze-property-options.directive';
import { BreezePropertyValidatorDirective } from './breeze-property-validator.directive';
import { BreezePropertyDirective } from './breeze-property.directive';

@Directive({
  selector: '[ngModel][breezeProperty]',
  exportAs: 'bjsPropertyRequired'
})
export class BreezePropertyRequiredDirective implements OnDestroy {
  private subscription = new Subscription();

  isRequired = false;
  constructor(
    property: BreezePropertyDirective,
    elementRef: ElementRef,
    renderer: Renderer2,
    options: BreezePropertyConfig,
    @Optional() modelSource?: BreezeModelSourceDirective,
    @Optional() entityTypeSource?: BreezeEntityTypeSourceDirective,
    @Optional() optionOverrides?: BreezePropertyOptionsDirective,
    @Optional() propertyValidators?: BreezePropertyValidatorDirective
  ) {
    const entityTypeFromModel$ = modelSource
      ? modelSource.breezeInstance$.pipe(switchMap(instance => (instance ? of(instance.entityType) : EMPTY)))
      : EMPTY;
    const entityTypeFromSource$ = entityTypeSource ? entityTypeSource.entityType$ : EMPTY;
    const entityType$ = merge(entityTypeFromSource$, entityTypeFromModel$).pipe(distinctUntilChanged());

    const options$ = optionsFrom(optionOverrides?.value$, options);

    const validators$ = merge(
      entityType$.pipe(map(et => et.getDataProperty(property.name)?.getAllValidators() ?? [])),
      propertyValidators?.validators$ ?? EMPTY
    );

    const isRequired$ = validators$.pipe(map(validators => validators.some(v => v.name === 'required')));

    const changes$ = combineLatest([isRequired$, options$]).pipe(
      map(([isRequired, options]) => ({
        isRequired,
        options
      })),
      tap(({ isRequired }) => {
        this.isRequired = isRequired;
      })
    );

    this.subscription.add(changes$.subscribe(setRequiredCssClass));

    function setRequiredCssClass({ isRequired, options }: { isRequired: boolean; options: BreezePropertyConfig }) {
      if (isRequired && options.applyRequiredCssClass) {
        renderer.addClass(elementRef.nativeElement, options.requiredCssClass);
      } else {
        renderer.removeClass(elementRef.nativeElement, options.requiredCssClass);
      }
    }
  }

  /**
   * @private
   * @ignore
   * */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
