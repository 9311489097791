import { forwardRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BreezeNotification } from './breeze-notification';

@Injectable({ providedIn: 'root', useExisting: forwardRef(() => InternalUnitOfWorkNotificationService) })
export abstract class UnitOfWorkNotificationService {
  abstract get notifications$(): Observable<BreezeNotification>;
}

@Injectable({ providedIn: 'root' })
export class InternalUnitOfWorkNotificationService implements UnitOfWorkNotificationService {
  private notificationsSubject = new Subject<BreezeNotification>();

  publish<T extends BreezeNotification>(notification: T) {
    this.notificationsSubject.next(notification);
  }

  get notifications$() {
    return this.notificationsSubject.asObservable();
  }
}
