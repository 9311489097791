import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { UserReportWorkspaceFilter } from '../models';
import { userReportWorkspaceFilterQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class UserReportWorkspaceFilterRepositoryService extends RepositoryService<UserReportWorkspaceFilter> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, userReportWorkspaceFilterQueries.selectAll);
  }
}
