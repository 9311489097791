import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { routeResolveEntities, RoutingEventsService } from '@mri-platform/shared/core';
import { Observable } from 'rxjs';
import { OrganisationPrincipal } from '../models';
import { OrganisationRepositoryService } from './organisation-repository.service';

@Injectable({ providedIn: 'root' })
export class OrganisationDtoResolver implements Resolve<OrganisationPrincipal[]> {
  constructor(
    private repo: OrganisationRepositoryService,
    private router: Router,
    private routingEvents: RoutingEventsService,
    private errorPolicyService: ErrorPolicyService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<OrganisationPrincipal[]> {
    return this.routingEvents.isInitialNavigation$.pipe(
      routeResolveEntities(
        () => this.repo.executeQuery(this.repo.baseQuery.noTracking()),
        this.router,
        this.errorPolicyService
      )
    );
  }
}
