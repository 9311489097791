import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AutoGeneratedKeyType, MetadataStore } from 'breeze-client';
/// </code-import>

export interface IdentityPrincipal {
  id: string;
  isDeleted: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  type: string;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export abstract class IdentityPrincipal extends BaseEntity {
  /// <code> Place custom code between <code> tags
  private static typeNameSuffix = 'Principal';
  static groupDiscriminator = EntityName.Group.replace(IdentityPrincipal.typeNameSuffix, '');
  static organisationDiscriminator = EntityName.Organisation.replace(IdentityPrincipal.typeNameSuffix, '');
  static userDiscriminator = EntityName.User.replace(IdentityPrincipal.typeNameSuffix, '');

  static basePropName(name: keyof IdentityPrincipal) {
    return name;
  }

  static register(metadataStore: MetadataStore) {
    metadataStore.setEntityTypeForResourceName('ExternalUsers', EntityName.User);
    metadataStore.setEntityTypeForResourceName('IdentityPrincipals', EntityName.IdentityPrincipal);

    const et = metadataStore.getAsEntityType(EntityName.IdentityPrincipal, false);

    // note: we've turn off server-side identity generation because of a limitation described here:
    // https://github.com/artiomchi/FlexLabs.Upsert/issues/125
    // however, we still want the convenience of letting client-side breeze assign the id for us so we override the
    // default key generation strategy
    [et, ...et.subtypes].forEach(t => (t.autoGeneratedKeyType = AutoGeneratedKeyType.Identity));

    et.subtypes
      .flatMap(st => st.dataProperties.filter(p => p.name === IdentityPrincipal.basePropName('type')))
      .forEach(p => {
        p.defaultValue = p.parentType.shortName.replace(IdentityPrincipal.typeNameSuffix, '');
      });
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
