import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface BusinessTransactionLock {
  key: string;
  expiry?: Date;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class BusinessTransactionLock extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
