import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { NamingConvention } from 'breeze-client';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { BreezeEntityTypeSourceDirective } from './breeze-entity-type-source.directive';
import { BreezeFormErrorComponent } from './breeze-form-error.component';
import { BreezeFormErrorDirective } from './breeze-form-error.directive';
import { BreezeKeyedValidationFilterPipe } from './breeze-keyed-validation-filter.pipe';
import { BreezeModelPropertyValidatorDirective } from './breeze-model-property-validator.directive';
import { BreezeModelSourceDirective } from './breeze-model-source.directive';
import { BreezePropertyConfig, defaultBreezePropertyConfig } from './breeze-property-config';
import { BreezePropertyOptionsDirective } from './breeze-property-options.directive';
import { BreezePropertyRequiredDirective } from './breeze-property-required.directive';
import { BreezePropertyValidatorDirective } from './breeze-property-validator.directive';
import { BreezePropertyDirective } from './breeze-property.directive';
import { BreezeValidationMessagePipe } from './breeze-validation-message.pipe';
import { FormErrorBaseComponent } from './form-error-base.component';
import { BREEZE_ENTITY_REGISTRATION_FUNC, BREEZE_METADATA_TOKEN, BreezeEntityRegistryFunc } from './injection-tokens';
import { metadataStoreProvider } from './metadata-store.provider';
import { UnitOfWorkRouteReuseStrategy } from './unit-of-work-route-reuse-strategy';
import { registerValidators } from './validators/register-validators';

export interface BreezeEntityModuleConfig {
  metadata?: object;
  entityRegistration?: BreezeEntityRegistryFunc;
  propertyOptions?: Partial<BreezePropertyConfig>;
}

const PUBLIC_UI = [
  BreezeFormErrorComponent,
  BreezeFormErrorDirective,
  BreezeModelPropertyValidatorDirective,
  BreezeModelSourceDirective,
  BreezePropertyDirective,
  BreezePropertyOptionsDirective,
  BreezePropertyRequiredDirective,
  BreezeEntityTypeSourceDirective,
  BreezePropertyValidatorDirective,
  BreezeKeyedValidationFilterPipe,
  BreezeValidationMessagePipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [PUBLIC_UI, FormErrorBaseComponent],
  exports: [PUBLIC_UI]
})
export class BreezeEntityModule {
  static forRoot(config: BreezeEntityModuleConfig = {}): ModuleWithProviders<BreezeEntityModule> {
    const breezePropertyOptions: BreezePropertyConfig = {
      ...defaultBreezePropertyConfig,
      ...config.propertyOptions
    };
    return {
      ngModule: BreezeEntityModule,
      providers: [
        metadataStoreProvider,
        { provide: RouteReuseStrategy, useClass: UnitOfWorkRouteReuseStrategy },
        { provide: BreezePropertyConfig, useValue: breezePropertyOptions },
        config.metadata ? { provide: BREEZE_METADATA_TOKEN, useValue: config.metadata } : [],
        { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: registerValidators, multi: true },
        config.entityRegistration
          ? { provide: BREEZE_ENTITY_REGISTRATION_FUNC, useValue: config.entityRegistration, multi: true }
          : []
      ]
    };
  }
  static forChild() {
    return BreezeEntityModule;
  }
  constructor(http: HttpClient) {
    // Configure Breeze adapters
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderJsonAdapter.register();
    AjaxHttpClientAdapter.register(http);
    DataServiceWebApiAdapter.register();
    NamingConvention.camelCase.setAsDefault();
  }
}

// make these public
export { BreezeFormErrorComponent };
