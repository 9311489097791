import { UnitOfWorkService } from '@mri/breeze-entity';
import { BookmarkRepositoryService } from './bookmark-repository.service';
import { DatasetRepositoryService } from './dataset-repository.service';
import { GroupRepositoryService } from './group-repository.service';
import { GroupMembershipRepositoryService } from './groupmembership-repository.service';
import { IdentityPrincipalRepositoryService } from './identity-principal-repository.service';
import { OrganisationWorkspaceInfoRepositoryService } from './organisation-workspace-info-repository.service';
import { OrganisationMembershipRepositoryService } from './organisationmembership-repository.service';
import { OrganisationWorkspaceRepositoryService } from './organisationworkspace-repository.service';
import { PbiReportRepositoryService } from './pbireport-repository.service';
import { ReportAccessRepositoryService } from './report-access-repository.service';
import { ReportActivityRecordRepositoryService } from './report-activity-record-repository.service';
import { ReportRepositoryService } from './report-repository.service';
import { ReportInfoRepositoryService } from './reportInfo-repository.service';
import { RoleRepositoryService } from './role-repository.service';
import { TemplateWorkspaceIconRepositoryService } from './template-workspace-icon-repository.service';
import { TemplateWorkspaceRepositoryService } from './template-workspace-repository.service';
import { UserReportFilterRepositoryService } from './user-report-filter-repository.service';
import { UserRepositoryService } from './user-repository.service';
import { UserReportWorkspaceFilterRepositoryService } from './user-workspace-filter-repository.service';
import { UserRoleRepositoryService } from './userrole-repository.service';

export const dataAccessProvider = [
  UnitOfWorkService,
  ReportInfoRepositoryService,
  ReportRepositoryService,
  PbiReportRepositoryService,
  BookmarkRepositoryService,
  UserRepositoryService,
  TemplateWorkspaceIconRepositoryService,
  OrganisationWorkspaceInfoRepositoryService,
  DatasetRepositoryService,
  RoleRepositoryService,
  UserRoleRepositoryService,
  OrganisationMembershipRepositoryService,
  GroupRepositoryService,
  GroupMembershipRepositoryService,
  IdentityPrincipalRepositoryService,
  ReportAccessRepositoryService,
  ReportActivityRecordRepositoryService,
  OrganisationWorkspaceRepositoryService,
  TemplateWorkspaceRepositoryService,
  UserReportFilterRepositoryService,
  UserReportWorkspaceFilterRepositoryService
];
