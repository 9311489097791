import { NamingConvention } from 'breeze-client';
import mapKeys from 'lodash-es/mapKeys';

type NamingConventionFunc = typeof NamingConvention.camelCase.serverPropertyNameToClient;

export function deserializeJson<T>(
  json: string,
  namingConvention: NamingConventionFunc = NamingConvention.camelCase.serverPropertyNameToClient
) {
  return mapKeys(JSON.parse(json), (_, k) => namingConvention(k)) as T;
}

export function serializeJson<T extends object>(
  value: T,
  namingConvention: NamingConventionFunc = NamingConvention.camelCase.clientPropertyNameToServer
) {
  return JSON.stringify(mapKeys(value, (_, k) => namingConvention(k)));
}
