export interface ModelWrapper<T> {
  value: T;
}

export type Wrapped<T> = {
  [Property in keyof T]: ModelWrapper<T[Property]>;
};

export const wrapModel = <T>(model: T): ModelWrapper<T> => ({ value: model });

export function wrapModels<T extends NonNullable<unknown>>(modelMap: T): Wrapped<T> {
  const entries = Object.entries(modelMap);
  return entries.reduce((result, [key, value]) => Object.assign(result, { [key]: { value } }), {} as Wrapped<T>);
}

export function rewrapModels<T extends NonNullable<unknown>>(wrapped: Wrapped<T>) {
  const entries = Object.entries<ModelWrapper<unknown>>(wrapped);
  return entries.reduce((result, [key, { value }]) => Object.assign(result, { [key]: { value } }), {} as Wrapped<T>);
}

export function rewrapModel<T extends NonNullable<unknown>>(wrapped: ModelWrapper<T>) {
  return wrapModel(wrapped.value);
}
