import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  Input,
  OnDestroy,
  Optional,
  Type,
  ViewContainerRef
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  share,
  skipUntil,
  switchMap,
  timeout,
  withLatestFrom
} from 'rxjs/operators';
import { BreezeFormErrorTypeService } from './breeze-form-error-type.service';
import { BreezeModelSourceDirective } from './breeze-model-source.directive';
import { BreezePropertyConfig } from './breeze-property-config';
import { BreezePropertyOptionsDirective } from './breeze-property-options.directive';
import { FormErrorComponent } from './form-error-base.component';
import { core } from 'breeze-client';

const { arrayEquals } = core;
const emptyErrors: ValidationErrors = {};

const isNgValidationErrorsEqual = (left: ValidationErrors | null, right: ValidationErrors | null) => {
  return arrayEquals(Object.keys(left ?? emptyErrors), Object.keys(right ?? emptyErrors));
};

function formErrorComponentTypeFrom(
  optionOverrides$: Observable<Partial<BreezePropertyConfig>> | undefined,
  formErrorComponent: Type<FormErrorComponent>
) {
  const overriddenComponent$ = optionOverrides$
    ? optionOverrides$.pipe(
        switchMap(({ formErrorComponent }) => (formErrorComponent ? of(formErrorComponent) : EMPTY))
      )
    : EMPTY;
  return merge(of(formErrorComponent), overriddenComponent$).pipe(distinctUntilChanged());
}

interface ComponentState {
  invalid: boolean;
  errors: ValidationErrors | null;
  dirty: boolean;
}

@Directive({ selector: '[ngModel][breezeProperty]' })
export class BreezeFormErrorDirective implements AfterViewInit, OnDestroy {
  private isAfterViewInitSubject = new Subject<boolean>();
  private isEnabledSubject = new BehaviorSubject<boolean>(true);
  private subscription = new Subscription();
  private controlSubscription?: Subscription;

  @Input() set breezeFormError(value: boolean) {
    this.isEnabledSubject.next(value);
  }

  constructor(
    private readonly formErrorTypeService: BreezeFormErrorTypeService,
    private readonly ngModel: NgControl,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef,
    @Optional() optionOverrides?: BreezePropertyOptionsDirective,
    @Optional() private modelSource?: BreezeModelSourceDirective
  ) {
    const componentType$ = formErrorComponentTypeFrom(optionOverrides?.value$, this.formErrorTypeService.componentType);
    const componentFactory$ = componentType$.pipe(map(ct => this.componentFactoryResolver.resolveComponentFactory(ct)));

    // note: not entirely sure why we're waiting until AfterViewInit lifecycle and then delaying with timeout before
    // creating component! It was in the sample code that I copied this from, so I guess the guy knew why!
    // For reference see: https://timdeschryver.dev/blog/a-practical-guide-to-angular-template-driven-forms#error-directive
    const isAfterViewInit$ = this.isAfterViewInitSubject.pipe(timeout(0), first(), share());

    const isEnabledChanges$ = this.isEnabledSubject.pipe(skipUntil(isAfterViewInit$));
    const initialState$ = isAfterViewInit$.pipe(
      withLatestFrom(this.isEnabledSubject, (_, enabled) => enabled),
      filter(enabled => enabled)
    );
    const renderChanges$ = merge(initialState$, isEnabledChanges$).pipe(distinctUntilChanged());

    const sub = combineLatest([renderChanges$, componentFactory$]).subscribe(([render, componentFactory]) => {
      this.viewContainerRef.clear();
      if (render) {
        this.createComponent(componentFactory);
      }
    });
    this.subscription.add(sub);
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /** @ignore */
  ngAfterViewInit() {
    this.isAfterViewInitSubject.next(true);
  }

  private createComponent(componentFactory: ComponentFactory<FormErrorComponent>) {
    // clean-up before replacing previous control?
    if (this.controlSubscription) {
      this.subscription.remove(this.controlSubscription);
      this.controlSubscription.unsubscribe();
    }

    const control = this.ngModel.control;
    if (control) {
      const errorContainer = this.viewContainerRef.createComponent(componentFactory);
      const component = errorContainer.instance;

      const invalid$ = control.statusChanges.pipe(
        map(status => status === 'INVALID'),
        distinctUntilChanged()
      );

      // note: listening to model changes to trigger changes to control is a workaround.
      // when the model changes, it DOES cause ngModel binding in our templates to cause the control value
      // to change. BUT for some reason `control.valueChanges` is not firing. And so we instead have to trigger
      // control changes ourselves when `this.modelSource.instance$` emits
      const modelChanges$ = this.modelSource ? this.modelSource.instance$ : EMPTY;

      const state$: Observable<ComponentState> = merge(invalid$, control.valueChanges, modelChanges$).pipe(
        map(() => ({
          dirty: control.dirty,
          invalid: control.status === 'INVALID',
          errors: control.errors
        })),
        distinctUntilChanged(
          (prev, curr) =>
            prev.dirty === curr.dirty &&
            prev.invalid === curr.invalid &&
            isNgValidationErrorsEqual(prev.errors, curr.errors)
        )
      );

      const sub = state$.subscribe(state => {
        component.errors = state.errors;
        component.dirty = state.dirty;
        component.invalid = state.invalid;
        this.viewContainerRef.injector.get(ChangeDetectorRef).markForCheck();
      });
      this.subscription.add(sub);
      this.controlSubscription = sub;
    }
  }
}
