import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { ReportInfo } from '../models';
import { reportInfoQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class ReportInfoRepositoryService extends RepositoryService<ReportInfo> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, reportInfoQueries.selectAll);
  }
}
