import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { OrganisationPrincipal } from '../models';
import { organisationQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class OrganisationRepositoryService extends RepositoryService<OrganisationPrincipal> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, organisationQueries.selectAll);
  }
}
