import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
/// </code-import>

export interface Role {
  id: string;
  description: string;
  isActive: boolean;
  isBuiltIn: boolean;
  isGlobal: boolean;
  isServicePrincipalOnly: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  periodEnd: Date;
  periodStart: Date;
  securityGroupId?: string;
  securityGroupType?: string;
  tenantKey: string;
}

/// <module-code> Place module level code between <module-code> tags
export enum InBuiltRole {
  SystemAdministrator = '4ac5be7f-cbce-4921-b897-66b0827fc5ff',
  ClientAdministrator = '0bf31d96-ba94-461b-8d23-2cf99a70666c',
  ViewerUser = 'd7eae666-b069-4b6f-84ac-356edb957be6',
  SupportUser = '8fc436d8-9d8f-45c3-b725-24e018d1e46d',
  GlobalServicesUser = '831c7f2b-aba8-4da6-9d51-564a55eac6c9',
  CreatorUser = '596246ac-6d88-477b-be62-da329aa76b93'
}

const roleEntries = Object.entries(InBuiltRole);
export function getInBuiltRoleName(id: InBuiltRole): string {
  const [name] = roleEntries.find(([_, value]) => value === id) ?? [];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return name!;
}
/// </module-code>

export class Role extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization = AuthzContextsActionMap.crudFor(EntityName.Role);

  static propName(name: keyof Role) {
    return name;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
