import { EntityQuery } from 'breeze-client';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface QueryHistoryEntry {
  query: object;
  queryKey: string;
  completedOn: string;
}

export interface EntityQueryDto {
  [key: string]: unknown;
  queryOptions: object;
}

export const normalizeEntityQueryDto = (query: EntityQuery) => {
  const { queryOptions: _, ...rest } = query.toJSON() as EntityQueryDto;
  return rest;
};

export const toEntityQueryKey = (query: Omit<EntityQueryDto, 'queryOptions'>) => JSON.stringify(query);

export function distinctQueryHistoryUntilChanged() {
  return (source$: Observable<QueryHistoryEntry[]>) =>
    source$.pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length));
}

export function isQueryHistoryEntryEquivalentTo(eq: EntityQuery) {
  const queryKey = toEntityQueryKey(normalizeEntityQueryDto(eq));
  return (entry: QueryHistoryEntry) => {
    return entry.queryKey === queryKey;
  };
}

export function hasQueryHistoryFor(eq: EntityQuery) {
  return (source$: Observable<QueryHistoryEntry[]>): Observable<boolean> =>
    source$.pipe(
      queryHistoryFor(eq),
      map(entries => entries.length > 0),
      distinctUntilChanged()
    );
}

export function queryHistoryFor(eq: EntityQuery) {
  const matcher = isQueryHistoryEntryEquivalentTo(eq);
  return (source$: Observable<QueryHistoryEntry[]>): Observable<QueryHistoryEntry[]> =>
    source$.pipe(
      map(entries => entries.filter(matcher)),
      distinctQueryHistoryUntilChanged()
    );
}
