import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { IdentityPrincipal } from '../models';
import { identityPrincipalQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class IdentityPrincipalRepositoryService extends RepositoryService<IdentityPrincipal> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, identityPrincipalQueries.selectAll);
  }
}
