import { EntityManager, EntityQuery } from 'breeze-client';

// A copy of this file is present in @mri/angular-breeze-in-memory-web-api
function modifySelectPathNames(query: EntityQuery) {
  // When nesting components in select clause the breeze library replaces
  // just one instance of . with _ but the result from the server returns all instances
  // of . to be replaced with _. So it has to be done manually here to avoid mismatch
  // in the return types.
  if (query.selectClause) {
    query.selectClause._pathNames = query.selectClause._pathNames.map(x =>
      x
        .split(/[._]/)
        .map((x, i) => (i !== 0 ? x.charAt(0).toUpperCase() + x.slice(1) : x))
        .join('_')
    );
  }

  return query;
}

export function patchEntityManager(emclass: { new (): EntityManager }) {
  const proto = emclass.prototype;
  const originalExecuteQueryLocally = proto.executeQueryLocally;
  proto.executeQueryLocally = function (query: EntityQuery) {
    return originalExecuteQueryLocally.call(this, modifySelectPathNames(query));
  };
}
