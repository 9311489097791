import { EntityQuery } from 'breeze-client';
import { OrganisationWorkspaceInfo } from '../../models';

const baseQuery = EntityQuery.from('WorkspaceInfos');
const withImage = baseQuery.expand(OrganisationWorkspaceInfo.propName('templateWorkspaceIcon'));

export const organisationWorkspaceInfoQueries = {
  selectAll: baseQuery,
  selectWithImage: withImage
};
