import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { RoutingEventsService, routeResolveEntities } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { Observable, first, map, switchMap } from 'rxjs';
import { UserReportFilter } from '../models';
import { userReportFilterQueries } from './queries';
import { UserReportFilterRepositoryService } from './user-report-filter-repository.service';

@Injectable({ providedIn: 'root' })
export class UserFilterResolver implements Resolve<UserReportFilter | undefined> {
  constructor(
    private repo: UserReportFilterRepositoryService,
    private router: Router,
    private routingEvents: RoutingEventsService,
    private errorPolicyService: ErrorPolicyService,
    private authService: AuthService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<UserReportFilter | undefined> {
    return this.routingEvents.isInitialNavigation$.pipe(
      routeResolveEntities(
        () =>
          this.authService.userLoaded$.pipe(
            first(),
            switchMap(user => {
              const predicate = Predicate.and(
                new Predicate(UserReportFilter.propName('userId'), FilterQueryOp.Equals, user.id)
              );
              const userFilterQuery = userReportFilterQueries.selectExcludedReports.where(predicate);

              //TODO: Add Query history after fixing the issue with unit of work state while switching client
              return this.repo.executeQuery(userFilterQuery);
            }),
            map(x => x[0])
          ),
        this.router,
        this.errorPolicyService
      )
    );
  }
}
