import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { Role } from '../models';
import { roleQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class RoleRepositoryService extends RepositoryService<Role> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, roleQueries.selectAll);
  }
}
