import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { routeResolveEntities, RoutingEventsService } from '@mri-platform/shared/core';
import { Observable } from 'rxjs';
import { Role } from '../models';
import { RoleRepositoryService } from './role-repository.service';

@Injectable({ providedIn: 'root' })
export class RolesResolver implements Resolve<Role[]> {
  constructor(
    private repo: RoleRepositoryService,
    private router: Router,
    private routingEvents: RoutingEventsService,
    private errorPolicyService: ErrorPolicyService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Role[]> {
    return this.routingEvents.isInitialNavigation$.pipe(
      routeResolveEntities(this.repo.all, this.router, this.errorPolicyService)
    );
  }
}
