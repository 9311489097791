import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function pluckPath<R>(properties: string[]) {
  const length = properties.length;
  if (length === 0) {
    throw new Error('list of properties cannot be empty.');
  }
  return (source$: Observable<unknown>) =>
    source$.pipe(
      map(state => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let currentValue: any = state;
        for (let i = 0; i < length; i++) {
          const v = currentValue?.[properties[i]];
          if (typeof v !== 'undefined') {
            currentValue = v;
          } else {
            // type safety is a responsibility of the caller. If the caller is aware the property might return undefined,
            // then it should specify that. EG: pluckPath<string | undefined>(['a', 'b', 'c']).
            return undefined as R;
          }
        }
        return currentValue as R;
      })
    );
}
