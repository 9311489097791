import { EntityQuery, FilterQueryOp } from 'breeze-client';
import { IdentityPrincipal } from '../../models';

const baseQuery = EntityQuery.from('IdentityPrincipals');

const selectOrganisationsAndGroups = baseQuery
  .where(IdentityPrincipal.basePropName('type'), FilterQueryOp.In, [
    IdentityPrincipal.groupDiscriminator,
    IdentityPrincipal.organisationDiscriminator
  ])
  .orderBy(`${IdentityPrincipal.basePropName('type')} desc,${IdentityPrincipal.basePropName('name')}`);

export const identityPrincipalQueries = {
  selectAll: baseQuery,
  selectOrganisationsAndGroups
};
