import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { Bookmark } from '../models';
import { bookmarkQueries } from './queries/bookmark-queries';
@Injectable({ providedIn: 'root' })
export class BookmarkRepositoryService extends RepositoryService<Bookmark> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, bookmarkQueries.selectAll);
  }
}
