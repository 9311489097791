import { TemplateWorkspaceIcon } from './template-workspace-icon';
import { TemplateWorkspace } from './template-workspace';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface TemplateWorkspaceIconText {
  initials: string;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class TemplateWorkspaceIconText extends TemplateWorkspaceIcon {
  /// <code> Place custom code between <code> tags
  // noinspection JSUnusedGlobalSymbols
  /**
   * note: this is a workaround for the code generator insisting on adding an import for TemplateWorkspace
   */
  static templateWorkspaceAuthz = TemplateWorkspace.authorization;
  /// </code>
  // Generated code. Do not place code below this line.
}
