import { EntityQuery, FilterQueryOp } from 'breeze-client';
import { ReportActivityRecord, ReportActivityType } from '../../models';

const baseQuery = EntityQuery.from('ReportActivityRecords');

const selectViews = baseQuery
  .where(ReportActivityRecord.propName('activityType'), FilterQueryOp.Equals, ReportActivityType.View)
  .orderByDesc(ReportActivityRecord.propName('activityDate'));

export const reportActivityRecordQueries = {
  selectAll: baseQuery,
  selectViews
};
