import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { OrganisationWorkspace } from '../models';
import { organisationWorkspacesQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class OrganisationWorkspaceRepositoryService extends RepositoryService<OrganisationWorkspace> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, organisationWorkspacesQueries.selectAll);
  }
}
