import { BaseEntity } from '../base-entity';
import { TemplateWorkspace } from './template-workspace';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface TemplateWorkspaceTargetSettings {
  templateWorkspaceId: string;
  region: string;
  capacityId: string;
  gatewayId: string;
  isRegionNeutralFallback: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  templateWorkspace: TemplateWorkspace;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class TemplateWorkspaceTargetSettings extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
