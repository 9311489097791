import { BaseEntity } from '../base-entity';
import { Dataset } from './dataset';
import { OrganisationWorkspace } from './organisation-workspace';
import { Report } from './report';
import { ReportInfo } from './report-info';
import { UserReportFilter } from './user-report-filter';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface UserReportWorkspaceFilter {
  userId: string;
  workspaceId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  userReportFilter: UserReportFilter;
  workspace: OrganisationWorkspace;
}

/// <module-code> Place module level code between <module-code> tags
type EntityType = ReportInfo | Report | Dataset;
export function excludeFilteredEntities<T extends EntityType>(
  entities: T[],
  workspaceFilters: UserReportWorkspaceFilter[]
): T[] {
  const filteredWorkspaceIds = workspaceFilters.map(x => x.workspaceId);
  return entities.filter(e => !filteredWorkspaceIds.includes(e.organisationWorkspaceId));
}
/// </module-code>

export class UserReportWorkspaceFilter extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName(name: keyof UserReportWorkspaceFilter) {
    return name;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
