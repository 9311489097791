import { BaseEntity } from '../base-entity';
import { IdentityPrincipal } from './identity-principal';
import { PbiOrganisationWorkspace } from './pbi-organisation-workspace';
import { Report } from './report';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction, CrudAllAction } from '@mri-platform/resource-authz';
/// </code-import>

export interface PbiReport {
  id: string;
  createdDate: Date;
  datasetId: string;
  description?: string;
  embedUrl: string;
  isDeleted: boolean;
  isDraft: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  name: string;
  ownerId?: string;
  pbiWorkspaceId: string;
  periodEnd: Date;
  periodStart: Date;
  reportId: string;
  tenantKey: string;
  webUrl: string;
  owner: IdentityPrincipal;
  pbiWorkspace: PbiOrganisationWorkspace;
  report: Report;
}

/// <module-code> Place module level code between <module-code> tags
export enum PbiReportCustomAction {
  Download = 'Download',
  Import = 'Import'
}
/// </module-code>

export class PbiReport extends BaseEntity {
  /// <code> Place custom code between <code> tags
  constructor() {
    super();
    this.createdDate = new Date();
  }

  static authorization = {
    ...AuthzContextsActionMap.crudFor(EntityName.PbiReport),
    [PbiReportCustomAction.Download]: {
      action: PbiReportCustomAction.Download,
      resource: EntityName.PbiReport
    },
    [PbiReportCustomAction.Import]: {
      action: PbiReportCustomAction.Import,
      resource: EntityName.PbiReport
    },
    [CrudAction.Delete]: AuthzContext.updateFor(
      BaseEntity.qualifiedPropName<PbiReport>(EntityName.PbiReport, 'isDeleted')
    ),
    [CrudAllAction.Delete]: AuthzContext.updateAllFor(
      BaseEntity.qualifiedPropName<PbiReport>(EntityName.PbiReport, 'isDeleted')
    )
  };

  static draftPrefix = '[Draft] ';
  static unpublishedPrefix = '[Unpublished] ';

  static propName<K1 extends keyof PbiReport>(k1: K1): string;
  static propName<K1 extends keyof PbiReport, K2 extends keyof PbiReport[K1]>(k1: K1, k2: K2): string;
  static propName(...keys: string[]) {
    return super.getPropPath(keys);
  }

  static baseReportName(pbiReportName: string) {
    return pbiReportName.replace(this.draftPrefix, '');
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
