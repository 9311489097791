import {
  BreezeEvent,
  Entity,
  EntityChangedEventArgs,
  EntityManager,
  EntityProperty,
  HasChangesChangedEventArgs,
  PropertyChangedEventArgs,
  ValidationError,
  ValidationErrorsChangedEventArgs
} from 'breeze-client';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import xor from 'lodash-es/xor';

export function fromPropertyChangedEvent(entity: Entity): Observable<PropertyChangedEventArgs> {
  return fromBreezeEvent(entity.entityAspect.propertyChanged);
}

export function fromEntityValidationErrorsChangedEvent(entity: Entity): Observable<ValidationErrorsChangedEventArgs> {
  return fromBreezeEvent(entity.entityAspect.validationErrorsChanged);
}

export const isValidationErrorsEqual = (x: ValidationError[], y: ValidationError[]) => xor(x, y).length === 0;

export function fromPropertyValidationErrorsChangedEvent(
  entity: Entity,
  property: string | EntityProperty
): Observable<ValidationError[]> {
  return fromEntityValidationErrorsChangedEvent(entity).pipe(
    map(evt => evt.entity.entityAspect.getValidationErrors(property as string)),
    distinctUntilChanged(isValidationErrorsEqual)
  );
}

export function fromPropertyValidationErrors(
  entity: Entity,
  property: string | EntityProperty
): Observable<ValidationError[]> {
  return fromEntityValidationErrorsChangedEvent(entity).pipe(
    map(evt => evt.entity.entityAspect.getValidationErrors(property as string)),
    startWith(entity.entityAspect.getValidationErrors(property as string)),
    distinctUntilChanged(isValidationErrorsEqual)
  );
}

export function fromHasChangesChangedEvent(em: EntityManager): Observable<HasChangesChangedEventArgs> {
  return fromBreezeEvent(em.hasChangesChanged);
}

export function fromEntityChangeEvent(em: EntityManager): Observable<EntityChangedEventArgs> {
  return fromBreezeEvent(em.entityChanged);
}

export function fromValidationErrorsChangedEvent(em: EntityManager): Observable<ValidationErrorsChangedEventArgs> {
  return fromBreezeEvent(em.validationErrorsChanged);
}

export function fromBreezeEvent<T>(evtProperty: BreezeEvent<T>): Observable<T> {
  return new Observable<T>(subscriber => {
    const key = evtProperty.subscribe(evt => {
      subscriber.next(evt);
    });
    return () => {
      evtProperty.unsubscribe(key);
    };
  });
}
