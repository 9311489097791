import { Directive, Input } from '@angular/core';
import { Entity } from 'breeze-client';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isEntity } from './type-guard-functions';

@Directive({
  selector: '[breezeModelSource]'
})
export class BreezeModelSourceDirective {
  private instance = new ReplaySubject<object | undefined>(1);
  instance$ = this.instance.asObservable();
  breezeInstance$ = this.instance.pipe(
    filter((instance): instance is Entity | undefined => (instance ? isEntity(instance) : true))
  );

  @Input() set breezeModelSource(value: object | undefined) {
    this.instance.next(value);
  }
}
