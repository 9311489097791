import { Type } from '@angular/core';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreezeFormErrorComponent } from './breeze-form-error.component';
import { FormErrorComponent } from './form-error-base.component';

export abstract class BreezePropertyConfig {
  /**
   * The css class to add to the DOM element that is bound to an `ngModel` to indicate that input is required
   * @default 'breeze-property--required'
   * */
  abstract requiredCssClass: string;
  /**
   * Should the css class that indicates input is required be added to the DOM element or no?
   * @default true
   * */
  abstract applyRequiredCssClass: boolean;
  /**
   * The component class that will be used to render validation error messages to the DOM
   * @default `BreezeFormErrorComponent`
   * */
  abstract formErrorComponent: Type<FormErrorComponent>;
}

export const defaultBreezePropertyConfig: BreezePropertyConfig = {
  requiredCssClass: 'breeze-property--required',
  applyRequiredCssClass: true,
  formErrorComponent: BreezeFormErrorComponent
};

export function optionsFrom(
  optionOverrides$: Observable<Partial<BreezePropertyConfig>> | undefined,
  baseOptions: BreezePropertyConfig = defaultBreezePropertyConfig
): Observable<BreezePropertyConfig> {
  return merge(of(baseOptions), optionOverrides$ ? optionOverrides$.pipe(map(o => ({ ...baseOptions, ...o }))) : EMPTY);
}
