import { Injectable } from '@angular/core';
import { RepositoryService, UnitOfWorkService } from '@mri/breeze-entity';
import { MetadataStore } from 'breeze-client';
import { UserRole } from '../models';
import { userRoleQueries } from './queries';

@Injectable({ providedIn: 'root' })
export class UserRoleRepositoryService extends RepositoryService<UserRole> {
  constructor(uow: UnitOfWorkService, metadataStore: MetadataStore) {
    super(uow, metadataStore, userRoleQueries.selectAll);
  }
}
